import { generateId } from "@/modules/utils";

type CallbackFunction = (...args: any[]) => any;

export class Callback {
  private static _callbacks: Record<string, CallbackFunction> = {};

  public static attach(callback: CallbackFunction): string {
    let id = generateId();
    this._callbacks[id] = callback;
    return id;
  }

  public static detach(id: string): void {
    if (this._callbacks[id]) {
      delete this._callbacks[id];
    }
  }

  public static call(id: string, ...args: unknown[]): unknown {
    if (this._callbacks[id]) {
      return this._callbacks[id](...args);
    }
  }
}
