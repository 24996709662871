import { Locale } from "../modules/locale";

Locale.getInstance().registerKeySet("en", "general", {
  languages: {
    en: "English",
    fr: "French",
  },
  previous: "Previous",
  next: "Next",
  yes: "Yes",
  no: "No",
  ok: "OK",
  cancel: "Cancel",
  close: "Close",
  reset: "Reset",
  save: "Save",
  login: "Login",
  logout: "Logout",
  loading: "Loading",
  register: "Register",
  modify: "Change",
  finish: "Finish",
  proceed: "Continue",
  apply: "Apply",
  clear: "Clear",
  or: "or",
  selectAll: "Select all",
  deselectAll: "Deselect all",
  unsavedChanges:
    "You have unsaved changes. Are you sure you want to leave this page?",
});

Locale.getInstance().registerKeySet("fr", "general", {
  languages: {
    en: "Anglais",
    fr: "Français",
  },
  previous: "Précédent",
  next: "Suivant",
  yes: "Oui",
  no: "Non",
  ok: "OK",
  cancel: "Annuler",
  close: "Fermer",
  reset: "Réinitialiser",
  save: "Sauvegarder",
  login: "Connexion",
  logout: "Déconnexion",
  loading: "Chargement",
  register: "Inscription",
  modify: "Modifier",
  finish: "Terminer",
  proceed: "Continuer",
  apply: "Appliquer",
  clear: "Effacer",
  or: "ou",
  selectAll: "Sélectionner tout",
  deselectAll: "Désélectionner tout",
  unsavedChanges:
    "Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter cette page?",
});

// Path: general.ts
