import Minze, { Attrs, EventListeners, MinzeElement, Reactive } from "minze";
import { Font } from "@/constants";
import { generateId, escapeHtml, unescapeHtml } from "@/modules/utils";

interface InputFieldChangeEvent {
  fieldId: string;
  value: string;
}

export interface BSelect {
  selectOptions: string;
  selectOptionsLabels: string;
  placeholder: string;
  value?: string;
  internalValue?: string;
  height?: string;
  type?: string;
  nameAttr?: string;
  label?: string;
  padding?: string;
  fontSize?: string;
  error?: any;
  defaultValue?: string;
  heightValue?: string;
}

export class BSelect extends MinzeElement {
  private elementId: string = generateId();
  reactive: Reactive = [
    ["value", "", true],
    ["internal-value", "", false],
  ];

  attrs: Attrs = [
    ["placeholder", ""],
    ["height", "48px"],
    "type",
    "name-attr",
    ["default-value", ""],
    "label",
    ["padding", "12px 16px"],
    ["fontSize", "14px"],
    ["select-options", ""],
    ["select-options-labels", ""],
    ["error", false],
  ];

  static observedAttributes = [
    "placeholder",
    "options",
    "height",
    "type",
    "name-attr",
    "id",
    "label",
    "default-value",
    "padding",
    "select-options",
    "select-option-labels",
    "error",
  ];

  afterAttributeChange = (name: string, oldValue: string, newValue: string) => {
    if (name === "default-value" && oldValue !== newValue) {
      this.internalValue = newValue ?? "";
      this.value = escapeHtml(this.internalValue);

      const currentElement = this.select(`[data-id="${this.elementId}"]`);
      if (currentElement as any) {
        (currentElement as any).value = escapeHtml(newValue);
      }
      this.rerender();
    }

    this.heightValue = this.height ? this.height : "48px";
  };

  changeSelect = (event: Event) => {
    this.internalValue = unescapeHtml((event as any).target.value);
    this.value = escapeHtml(this.internalValue);
    this.dispatch("change", { value: this.internalValue, target: this });
  };

  html = () => {
    let showError = typeof this.error === "string" && this.error.length > 0;
    let placeholder =
      typeof this.placeholder === "boolean" ? "" : this.placeholder;
    return `
      <b-box>
        ${this.label ? `<label for="${this.id}">${this.label}</label>` : ""}
        <select
          placeholder="${placeholder}"
          ${this.type ? `type="${this.type}"` : ""}
          name="${this.nameAttr}"
					data-id="${this.elementId}"
          value="${escapeHtml(this.internalValue)}"
          class="input ${this.error ? "error" : ""}"
        >
          ${placeholder ? `<option value="" ${this.internalValue === "" ? "selected" : ""}">${placeholder}</option>` : ""}
          ${this.renderOptions()}
        </select>
      </b-box>
      ${showError ? `<b-error error="${this.error}"></b-error>` : ""}
    `;
  };

  renderOptions = () => {
    if (!this.selectOptions) {
      return "";
    }

    let options = this.selectOptions.split(",");

    return options
      .map((option: any, index: number) => {
        return this.renderOption(option, index);
      })
      .join("");
  };

  renderOption = (value: string, index: number) => {
    let label = value;
    if (this.selectOptionsLabels && this.selectOptionsLabels.length > 0) {
      let labels = this.selectOptionsLabels.split(",");
      if (labels.length > index) {
        label = labels[index];
      }
    }

    return `<option value="${escapeHtml(value)}">${escapeHtml(label)}</option>`;
  };

  css = () => `
    :host {
      width: 100%;
    }

    .input:focus-visible {
      outline: unset;
    }

    .input {
      font-family: ${Font.family};
      width: 100%;
      border: unset;
      ${this.heightValue ? `height: ${this.heightValue};` : ""}
      ${this.fontSize ? `font-size: ${this.fontSize};` : ""}
      ${this.padding ? `padding: ${this.padding};` : ""}
      background: #fff;
      color: #1E007D;
      margin-top: 4px;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 99px;
    }

    label {
      font-family: ${Font.family};
      color:  #1E007D;
      font-size: 14px;
      font-weight: ${Font.weight.bold};
    }

    .error {
      background: #FFEBD7;
      border-bottom: 3px solid #FF4600;
    }
  `;

  eventListeners: EventListeners = [["select", "change", this.changeSelect]];
}
