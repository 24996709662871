import { EventListeners, MinzeElement, Reactive, Attrs } from "minze";
import { Font } from "@/constants";
import CloseIcon from "iconoir/icons/solid/xmark-circle.svg?raw";

export interface BCloseableModal {
  modalId: string;
  modalStatus: string;
  modalTitle: string;
  titleSize: string;
  noCloseButton?: boolean;
  maxWidth?: string;
  theme?: string;
}

export class BCloseableModal extends MinzeElement {
  attrs: Attrs = [
    "modal-id",
    "modal-title",
    "no-close-button",
    "close",
    "max-width",
    ["theme", "default"],
  ];
  static observedAttributes = ["close"];
  _authHandler: any = null;

  onCloseButtonClick() {
    this.closeModal();
  }

  onOverlayClick() {
    this.closeModal();
  }

  closeModal() {
    this.dispatch("close");
    this.remove();
  }

  afterAttributeChange(name: string, oldValue: string, newValue: string) {
    if (name === "close" && newValue === "true") {
      this.closeModal();
    }
  }

  html = () => `
   <div id="b-modal-overlay" class="b-modal-overlay">
    </div>
    <div class="b-modal-wrapper">
      <div id="b-modal" class="b-modal">
        ${
          !this.modalTitle && this.noCloseButton
            ? ""
            : `
          <div class="header">
            ${(this.modalTitle && `<div class="title">${this.modalTitle}</div>`) || ""}
            ${this.noCloseButton ? "" : `<div id="b-close-button">${CloseIcon}</div>`}
          </div>
        `
        }
        <slot></slot>
      </div>
    </div>
  `;

  css = () => {
    let theme = "";

    if (this.theme === "neat") {
      theme = `
        .b-modal, .b-modal-wrapper {
         clip-path: none;
        }

        .b-modal {
          padding: 16px;
          border-radius: 20px;
        }

        .title {
          text-transform: uppercase;
          font-size: 20px;
        }
      `;
    }

    return `
      :host {
        z-index: 100000;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
      }

      #b-close-button {
        cursor: pointer;
      }

      .b-modal {
        background: #fff;
        padding: 42px;
        ${this.maxWidth ? `max-width: ${this.maxWidth}` : ``}
      }

      .b-modal-wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2px;
        background: #000;
      }

      @media screen and (max-width: 730px) {
        .b-modal-wrapper {
          width: 90%;
        }
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        min-width: 448px;
        width: 100%;
        height: 40px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      @media (max-width: 767px) {
        .b-modal {
          max-width: 100%;
        }
        .header {
          min-width: 0px;
        }
      }


      .title {
        font-family: ${Font.family};
        font-style: normal;
        font-weight: ${Font.weight.extraBold};
        font-size: 60px;
        line-height: 100%;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #000;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .b-modal-overlay {
        position: fixed; /* Stay in place */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(0,0,0,0.25);
        cursor: pointer;
      }


      ${theme}
    `;
  };

  eventListeners: EventListeners = [
    ["#b-modal-overlay", "click", this.onOverlayClick.bind(this)],
    ["#b-close-button", "click", this.onCloseButtonClick.bind(this)],
  ];
}
