import { MinzeElement, type Attrs, type Reactive, EventListeners } from "minze";
import { Locale } from "@/modules/locale";

Locale.getInstance().registerKeySet("en", "ssctext", {
  logout: "Logout",
});
Locale.getInstance().registerKeySet("fr", "ssctext", {
  logout: "Déconnexion",
});

export interface SscText {
  dataKey?: string;
}

export class SscText extends MinzeElement {
  attrs: Attrs = [["data-key", ""]];
  reactive: Reactive = [];
  eventListeners: EventListeners = [];
  static observedAttributes = ["data-key"];

  async onReady(): Promise<void> {}

  onDestroy(): void {}

  html = () => {
    if (this.dataKey) {
      let k = Locale.getKey(`ssctext.${this.dataKey}`);
      return `<span>${k}</span>`;
    }

    return "";
  };

  css = () => {
    return `
      :host {
      }
    `;
  };
}
