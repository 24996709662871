import { Attrs, MinzeAttrs, MinzeElement } from "minze";
import { Font } from "@/constants";

interface FontPreset {
  css?: string;
}

const fontPresets: Record<string, FontPreset> = {
  small: {
    css: `
      font-size: 14px;
      font-weight: ${Font.weight.regular};
      color: #000;
    `,
  },
  medium: {
    css: `
      font-size: 18px;
      font-weight: ${Font.weight.medium};
      color: #000;
    `,
  },
  large: {
    css: `
      font-size: 20px;
      font-weight: ${Font.weight.medium};
      color: #000;
    `,
  },
  smallExtraBold: {
    css: `
      font-size: 14px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  mediumExtraBold: {
    css: `
      font-size: 18px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  largeExtraBold: {
    css: `
      font-size: 20px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  titleDesktop: {
    css: `
      font-size: 90px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  titleDesktopSmall: {
    css: `
      font-size: 36px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  titleMobile: {
    css: `
      font-size: 36px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  titleMobileSmall: {
    css: `
      font-size: 24px;
      font-weight: ${Font.weight.extraBold};
      color: #000;
    `,
  },
  xLargeMediumBold: {
    css: `
      font-size: 24px;
      font-weight: ${Font.weight.medium};
      color: #000;
    `,
  },
  MediumMediumBold: {
    css: `
      font-size: 18px;
      font-weight: ${Font.weight.medium};
      color: #000;
    `,
  },
};

export interface BText {
  fontPreset?: string;
}

export class BText extends MinzeElement {
  attrs: MinzeAttrs = [["color", "#1E007D"], "font-preset"];

  get fontPresetStyle(): string {
    return (
      fontPresets[this.fontPreset || ""]?.css ??
      `
      font-size: 18px;
      font-weight: ${Font.weight.medium};
      color: #000;
    `
    );
  }

  html = () => `
    <div class="text">
      <slot></slot>
    </div>
  `;

  css = () => `
    .text {
      ${this.fontPresetStyle}
    }
  `;
}
