export class Storage {
  private static _instance: Storage;

  public static get instance(): Storage {
    if (!Storage._instance) {
      Storage._instance = new Storage(true);
    }
    return Storage._instance;
  }

  constructor(useLocal?: boolean) {
    if (useLocal) {
      this.useRam = false;
    } else {
      this.useRam = true;
    }
  }
  private useRam: boolean = false;
  private storage: any = {};

  getItem(key: string): any {
    let value: any = null;
    if (this.useRam) {
      value = this.storage.hasOwnProperty(key) ? this.storage[key] : null;
    } else {
      if (window.localStorage) {
        value = window.localStorage.getItem(key);
      } else {
        value = this.storage.hasOwnProperty(key) ? this.storage[key] : null;
      }
    }

    try {
      value = JSON.parse(value);
    } catch (e) {
      // do nothing
    }

    return value;
  }

  setItem(key: string, value: any): void {
    if (this.useRam) {
      this.storage[key] = value;
      return;
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    } else {
      this.storage[key] = value;
    }
  }

  removeItem(key: string): void {
    if (this.useRam) {
      delete this.storage[key];
      return;
    }

    if (window.localStorage) {
      window.localStorage.removeItem(key);
    } else {
      delete this.storage[key];
    }
  }

  clear(): void {
    if (this.useRam) {
      this.storage = {};
      return;
    }

    if (window.localStorage) {
      window.localStorage.clear();
    } else {
      this.storage = {};
    }
  }
}
