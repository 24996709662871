import { MinzeElement, type Attrs, type Reactive, EventListeners } from "minze"
import { Locale } from "@/modules/locale"
import { config, Style, Font, FontLato } from "@/constants";

Locale.getInstance().registerKeySet("fr", "footer", {
  termsAndConditions: "Termes et conditions",
  confidentialityPolicy: "Politique de confidentialité",
  contactUs: "Nous contacter",
  rights: "© 2023 Succès Scolaire - Tous droits réservés",
  links: {
    termsAndConditions:"https://www.successcolaire.ca/condition-dutilisation",
    confidentialityPolicy:"https://www.successcolaire.ca/politique-confidentialite",
    contactUs: "https://www.successcolaire.ca/nous-joindre"
  }
});

Locale.getInstance().registerKeySet("en", "footer", {
  termsAndConditions: "Terms and conditions",
  confidentialityPolicy: "Confidentiality policy",
  contactUs: "Contact us",
  rights: "© 2023 Succès Scolaire - All rights reserved",
  links: {
    termsAndConditions:"https://www.successcolaire.ca/condition-dutilisation",
    confidentialityPolicy:"https://www.successcolaire.ca/politique-confidentialite",
    contactUs: "https://www.successcolaire.ca/nous-joindre"
  }
});

export interface SscFooter {

}

export class SscFooter extends MinzeElement {
  attrs: Attrs = [];
  reactive: Reactive = [];
  eventListeners: EventListeners = [];
  static observedAttributes = [];

  async onReady(): Promise<void> {}

  onDestroy(): void {}

  html = () => {
    return `
      <div class="ssc-footer-container">
        <a href="${Locale.getKey("footer.links.termsAndConditions")}" target="_blank">${Locale.getKey("footer.termsAndConditions")}</a>
        <a href="${Locale.getKey("footer.links.confidentialityPolicy")}" target="_blank">${Locale.getKey("footer.confidentialityPolicy")}</a>
        <a href="${Locale.getKey("footer.links.contactUs")}" target="_blank">${Locale.getKey("footer.contactUs")}</a>
        <div style="flex: 1"></div>
        <div>${Locale.getKey("footer.rights")}</div>
        <!--<ssc-language-selector></ssc-language-selector>-->
      </div>
    `;
  };

  css = () => {
    return `
      :host {
      }

      .ssc-footer-container {
        display: flex;
        gap: 20px;
        padding: 0 20px;
        align-items: center;
        height: 100px;
        color: #8497AB;
        font-family: ${FontLato.family};
        font-weight: ${Font.weight.regular};
        font-size: 12px;

      }
    `;
  };
}
