import { Attrs, MinzeElement, Reactive } from "minze";
import { parseInlineStyle } from "@/modules/utils";
import { Font, FontLato } from "@/constants";

interface BtnPreset {
  css?: string;
  hoverCss?: string;
}

const btnPresets: Record<string, BtnPreset> = {
  default: {
    css: `
      border-radius: 50px;
      background-color: #d11d1d;
      padding: 0 24px;
      font-family: ${Font.family};
      font-size: 16px;
      line-height: 48px;
      font-weight: ${Font.weight.regular};
      color: #fff;
      height: 48px;
      cursor: pointer;
      transition: all .2s ease-in-out;
    `,
    hoverCss: `
      background-color: #fff;
      color: #d11d1d;
      box-shadow: 0 2px 4px rgba(0,0,0,.1);
      transition: all .2s ease-in-out;
    `,
  },

  sscPrimary: {
    css: `
      border-radius: 8px;
      background: #005B88;
      box-shadow: 0px 3px 4px -2px rgba(28, 36, 51, 0.10);
      color: #FFF;
      text-align: center;
      font-family: ${FontLato.family};
      font-size: 16px;
      font-style: normal;
      font-weight: ${FontLato.weight.bold};
      line-height: 24px; /* 150% */
      display: flex;
      padding: 12px 20px;
      align-items: flex-start;
      gap: 10px;
    `,
    hoverCss: `
    `,
  },
};

export interface BButton {
  preset?: string;
  onClick?: () => void;
}

export class BButton extends MinzeElement {
  attrs: Attrs = [["preset", "default"], "on-click"];

  static observedAttributes = ["preset"];

  get btnPreset(): BtnPreset | undefined {
    const preset = this.preset;
    return preset ? btnPresets[preset as keyof typeof btnPresets] : undefined;
  }

  html = () => `
    <button class="button" type="button">
      <slot></slot>
    </button>
  `;

  css = () => {
    return `
      :host {
        position: relative;
        box-sizing: border-box;
        display: block;
      }

      .button {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        ${this.btnPreset?.css}
      }

      .button:hover {
        ${this.btnPreset?.hoverCss}
      }
    `;
  };
}
