export const config = {
  debugInstance: true,
  projectName: 'ssc_webcomponents',
  firebase: {
    apiKey: "AIzaSyAjvqblbjkYiVhg5LsVfxWuLlS7P-m_yrs",
    authDomain: "successcolaire.firebaseapp.com",
    projectId: "successcolaire",
    storageBucket: "successcolaire.appspot.com",
    messagingSenderId: "929170106029",
    appId: "1:929170106029:web:bc5dd6035b643cd719c824",
    measurementId: "G-8YSWPBWRYW"
  },
  auth: {
    userData: "users/{{{userId}}}"
  },
  apis: {
    completeAccount: 'https://www.successcolaire.ca/functions/',
    register: 'https://www.successcolaire.ca/functions/',
    deleteAccount: 'https://www.successcolaire.ca/functions/',
    loginWithUsername: 'https://www.successcolaire.ca/functions/user/login_with_username',
    userIs: 'https://www.successcolaire.ca/functions/user/user_is'
  },
  backendApi: 'https://www.successcolaire.ca/functions/',
  routing: {
    routeGuard: ['profile'],
    ignoreRouteGuard: ['resetPassword'],
    routeProceedAfterSession: ['login, signup'],
    skipRouteLogging: ['login, signup','profile'],
    routingHost: 'https://ssc-webcomponents.web.app',
    routes: {
      en: {
        login: '/en/login',
        signup: '/en/signup',
        resetPassword: '/en/reset-password',
        forgotPassword: '/en/forgot-password',
      },
      fr: {
        login: '/fr/login',
        signup: '/fr/signup',
        resetPassword: '/fr/reset-password',
        forgotPassword: '/fr/forgot-password',
      },
    }
  },
  // ...
};
