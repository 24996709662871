import { EventListeners, MinzeElement, Reactive } from "minze";
import { Font } from "@/constants";
import CloseIcon from "iconoir/icons/solid/xmark-circle.svg?raw";

export interface BDrawerModal {
  modalId: string;
  modalStatus: string;
  modalTitle: string;
  titleSize: string;
}

export class BDrawerModal extends MinzeElement {
  attrs = ["modal-id", "modal-title", "no-close-button", "close"];
  static observedAttributes = ["close"];
  _authHandler: any = null;

  onCloseButtonClick() {
    this.closeModal();
  }

  onOverlayClick() {
    this.closeModal();
  }

  closeModal() {
    this.dispatch("close");
    this.remove();
  }

  onAttributeChange(name: string, oldValue: string, newValue: string) {
    if (name === "close" && newValue === "true") {
      this.closeModal();
    }
  }

  html = () => `
   <div id="b-modal-overlay" class="b-modal-overlay">
    </div>
    <div id="b-modal" class="b-modal">
      <div class="container">
        <div class="header">
          ${(this.modalTitle && `<div class="title">${this.modalTitle}</div>`) || ""}
          ${this.noCloseButton ? "" : `<div id="b-close-button">${CloseIcon}</div>`}
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  `;

  css = () => {
    return `
    :host {
      z-index: 100000;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
    }

    #b-close-button {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    .b-modal {
      /* right side drawer */
      position: fixed;
      right: 0;
      top: 0;
      max-width: 447px;
      width: 100%;
      height: 100%;
      bottom: 0;
      background: #fff;
      padding: 0px;
    }

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 42px;
      overflow: hidden;
      box-sizing: border-box;
    }

    .header {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      width: 100%;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      position: relative;
    }

    .content {
      flex: 1;
      width: 100%;
      height: 100%;
      order: 1;
      align-self: stretch;
      overflow: auto;
    }

     .title {
      font-family: ${Font.family};
      font-weight: ${Font.weight.extraBold};
      line-height: 120%;
      color: #1E007D;
      flex: none;
      width: 88%; /*so text wraps properly*/
      order: 0;
      flex-grow: 0;
      color: var(--brand-minuit, #1E007D);
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 40px;
      font-style: normal;
    }

    .b-modal-overlay {
      position: fixed; /* Stay in place */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgba(0,0,0,0.25);
      cursor: pointer;
    }

    @media (max-width: 447px) {
      .b-modal {
        max-width: 100%;
      }
      .header {
        min-width: 0px;
      }
      .title {
        font-size: 24px;
      }
    }



  `;
  };

  eventListeners: EventListeners = [
    ["#b-modal-overlay", "click", this.onOverlayClick.bind(this)],
    ["#b-close-button", "click", this.onCloseButtonClick.bind(this)],
  ];
}
