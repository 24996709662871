import { config } from "@/constants";
import { Storage } from "@/modules/storage";
import { Locale } from "@/modules/locale";
import { ConsoleLog } from "@/modules/logging";
const ROUTING_CONSTANTS = config.routing;
const DEBUG_ROUTING: boolean = false;

export class Routing {
  public static JoinURLParts(...parts: string[]): string {
    return parts
      .map((part) => (part ?? "").replace(/^\/+|\/+$/g, ""))
      .join("/");
  }
  public static ROUTES: any = ROUTING_CONSTANTS.routes;
  public static HOST: string = ROUTING_CONSTANTS.routingHost;
  public static SKIP_ROUTE_LOGGING: string[] =
    ROUTING_CONSTANTS.skipRouteLogging;
  public static ROUTE_GUARD: string[] = ROUTING_CONSTANTS.routeGuard;
  public static IGNORE_ROUTE_GUARD: string[] =
    ROUTING_CONSTANTS.ignoreRouteGuard;
  public static ROUTE_PROCEED_AFTER_SESSION: string[] =
    ROUTING_CONSTANTS.routeProceedAfterSession;

  _storage: Storage = Storage.instance;
  _lang: string = Locale.getInstance().getLanguage();
  _isDirty: boolean = false;

  constructor() {
    this.setLastPage();
    window.addEventListener(
      "beforeunload",
      this.beforeUnloadCallback.bind(this),
    );
  }

  public setDirty(): void {
    this._isDirty = true;
  }

  public clearDirty(): void {
    this._isDirty = false;
  }

  public beforeUnloadCallback(event: BeforeUnloadEvent): string | void {
    if (this._isDirty) {
      const message = Locale.getKey("general.unsavedChanges");
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    }
  }

  public getCurrentPage(): string {
    ConsoleLog(DEBUG_ROUTING, "getCurrentPage", "enter");
    let hostParts = window?.location?.href?.split("?") || [""];
    ConsoleLog(DEBUG_ROUTING, "getCurrentPage", "hostParts", hostParts);
    return hostParts[0];
  }

  public currentPageHasQueryString(): boolean {
    ConsoleLog(DEBUG_ROUTING, "currentPageHasQueryString", "enter");
    let hostParts = window?.location?.href?.split("?") || [""];
    return hostParts.length > 1;
  }

  public setLanguage(lang: string): void {
    this._lang = lang;
  }
  public getLanguage(): string {
    return this._lang;
  }

  private getLastPage(): string {
    ConsoleLog(DEBUG_ROUTING, "getLastPage", "enter");
    let lastPage = this._storage.getItem("lastPage") ?? Routing.HOST;
    ConsoleLog(DEBUG_ROUTING, "getLastPage", "lastPage", lastPage);
    return lastPage;
  }
  private setLastPage(): void {
    ConsoleLog(DEBUG_ROUTING, "setLastPage", "enter");
    let location: string = this.getCurrentPage();
    let ignoreable: string[] = this.getLocationListValuesFromRoutes(
      Routing.SKIP_ROUTE_LOGGING,
    );
    let isIgnored: boolean = this.isStringInList(location, ignoreable);
    let is404: boolean =
      location.indexOf(Routing.JoinURLParts(Routing.HOST, "404")) == 0;

    if (!isIgnored && !is404) {
      ConsoleLog(
        DEBUG_ROUTING,
        "setLastPage",
        "location",
        location,
        "isIgnored",
        isIgnored,
        "is404",
        is404,
      );
      this._storage.setItem("lastPage", window.location.href);
    }
  }
  private removeLastPage(): void {
    ConsoleLog(DEBUG_ROUTING, "removeLastPage", "enter");
    this._storage.removeItem("lastPage");
  }

  public goToLastPage(forceHome?: boolean): void {
    ConsoleLog(DEBUG_ROUTING, "goToLastPage", "enter", forceHome);
    let lastPage = this.getLastPage();
    this.removeLastPage();

    if (forceHome) {
      ConsoleLog(DEBUG_ROUTING, "goToLastPage", "force home", Routing.HOST);
      lastPage = Routing.HOST;
    }

    if (lastPage == this.getCurrentPage()) {
      ConsoleLog(DEBUG_ROUTING, "goToLastPage", "same page", Routing.HOST);
      this.goTo(Routing.HOST);
      return;
    }

    ConsoleLog(DEBUG_ROUTING, "goToLastPage", "last page", lastPage);
    this.goTo(lastPage);
  }

  public goTo(location: string, force?: boolean): void {
    ConsoleLog(DEBUG_ROUTING, "goTo", "enter", location, force);
    let currentPage = this.getCurrentPage();

    if (force) {
      ConsoleLog(DEBUG_ROUTING, "goTo", "force", location);
      window.location.href = location;
    }

    if (this.currentPageHasQueryString()) {
      ConsoleLog(DEBUG_ROUTING, "goTo", "has query string", location);
      if (location.indexOf(currentPage) == -1) {
        ConsoleLog(DEBUG_ROUTING, "goTo", "go to", location);
        window.location.href = location;
      } else {
        ConsoleLog(DEBUG_ROUTING, "goTo", "replace state", location);
        window.history.replaceState({}, "", location);
      }
    } else {
      if (location == currentPage) {
        ConsoleLog(DEBUG_ROUTING, "goTo", "stay on the page", location);
        return;
      }

      ConsoleLog(DEBUG_ROUTING, "goTo", "go to", location);
      window.location.href = location;
    }
  }

  public proceedAfterSession(): void {
    ConsoleLog(DEBUG_ROUTING, "proceedAfterSession", "enter");
    const lastPage = this.getLastPage();
    let isProceedAfterSession = this.isStringInList(
      lastPage,
      this.getLocationListValuesFromRoutes(Routing.ROUTE_PROCEED_AFTER_SESSION),
    );
    // if we're not on login or signup skip this flow
    if (!isProceedAfterSession) {
      ConsoleLog(
        DEBUG_ROUTING,
        "proceedAfterSession",
        "not on login or signup",
        Routing.HOST,
      );
      return;
    }

    //if previous page is from same host
    if (lastPage.indexOf(Routing.ROUTES.routingHost) > -1) {
      ConsoleLog(
        DEBUG_ROUTING,
        "proceedAfterSession",
        "previous page is from same host",
        lastPage,
      );
      //if last page is not signup or login
      if (isProceedAfterSession) {
        ConsoleLog(
          DEBUG_ROUTING,
          "proceedAfterSession",
          "last page is not signup or login",
          lastPage,
        );
        this.goToLastPage();
        return;
      }
    }
    ConsoleLog(
      DEBUG_ROUTING,
      "proceedAfterSession",
      "go to host",
      Routing.HOST,
    );
    this.goTo(Routing.HOST);
  }

  public guardCurrentRoute(isLoggedIn: boolean): void {
    ConsoleLog(DEBUG_ROUTING, "guardCurrentRoute", "enter", isLoggedIn);
    let isGuardedRoute = this.isStringInList(
      this.getCurrentPage(),
      this.getLocationListValuesFromRoutes(Routing.ROUTE_GUARD),
    );

    let isIgnoreGuardRoute = this.isStringInList(
      this.getCurrentPage(),
      this.getLocationListValuesFromRoutes(Routing.IGNORE_ROUTE_GUARD),
    );

    if (!isLoggedIn && isGuardedRoute && !isIgnoreGuardRoute) {
      ConsoleLog(
        DEBUG_ROUTING,
        "guardCurrentRoute",
        "not logged in and is guarded and not ignored",
        Routing.ROUTES.login,
      );
      let isLastPageGuarded = this.isStringInList(
        this.getLastPage(),
        this.getLocationListValuesFromRoutes(Routing.ROUTE_GUARD),
      );

      if (isLastPageGuarded) {
        ConsoleLog(
          DEBUG_ROUTING,
          "guardCurrentRoute",
          "last page is guarded",
          Routing.ROUTES.login,
        );
        this.goToLastPage(true);
      }

      this.goToLastPage();
    } else {
      ConsoleLog(
        DEBUG_ROUTING,
        "guardCurrentRoute",
        "logged in or not guarded or ignored",
        Routing.HOST,
      );
    }
  }

  public getRouteKeyFromCurrentLocation(lang?: string): string {
    let ln = lang || this.getLanguage();
    let currentRoute = this.getCurrentPage();
    let routes = Routing.ROUTES[ln];
    let ident: string = "";

    for (let k in routes) {
      if (currentRoute === Routing.JoinURLParts(Routing.HOST, routes[k])) {
        ident = k;
        break;
      }
    }

    return ident;
  }

  public getLocationListValuesFromRoutes(
    list: string[],
    lang?: string,
  ): string[] {
    return list.map((val: string) => {
      let path = Routing.ROUTES?.[lang || this.getLanguage()]?.[val] || "";
      if (!path) return path;

      return Routing.JoinURLParts(Routing.HOST, path);
    });
  }

  public isStringInList(str: string, list: string[]): boolean {
    return list.some((val: string) => {
      if (val && val.length > 0) {
        return str.indexOf(val) === 0;
      } else {
        return false;
      }
    });
  }

  public getRoute(route: string): string {
    return Routing.JoinURLParts(
      Routing.HOST,
      Routing.ROUTES[this.getLanguage()][route],
    );
  }
}
