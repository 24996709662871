import { EventListeners, MinzeElement } from "minze";
import { Font } from "@/constants";
import CloseIcon from "iconoir/icons/solid/xmark-circle.svg?raw";
import SuccessIcon from "iconoir/icons/regular/check.svg?raw";

export interface BToastContainer {
  dataMessage: string;
  dataTimeout: number;
}

export class BToastContainer extends MinzeElement {
  attrs = ["data-message", "data-timeout"];

  destroyToast = () => this.parentNode?.removeChild(this);

  onReady() {
    setTimeout(() => this.destroyToast(), this.dataTimeout);
  }

  closeToast = () => {
    this.destroyToast();
  };

  html() {
    return `
      <div id="toast-container">
        ${SuccessIcon}
        ${this.dataMessage}
        <button id="close-toast">
          ${CloseIcon}
        </button>
      </div>
    `;
  }

  css = () => `
    #toast-container {
      z-index: 10000;
      background: #1E007D;
      padding: 16px;
      position: fixed;
      bottom: 20px;
      left: 20px;
      display: flex;
      gap: 16px;
      align-items: center;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      line-height: 150%;
      color: #FFFFFF;
      animation-name: appear;
      animation-duration: 0.5s;
    }
    #close-toast {
      cursor: pointer;
      background: none;
      border: none;
    }
    @keyframes appear {
      0%   {transform: translateX(calc(-100% - 20px))}
      100% {transform: translateX(0)}
    }
  `;

  eventListeners: EventListeners = [["#close-toast", "click", this.closeToast]];
}
