import { TabAccountant } from "@/modules/tabAccountant";
import escape from "lodash/escape";
import unescape from "lodash/unescape";
export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
export const EMAIL_REGEX = /^[\p{L}\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/u;
export interface Attribute {
  name: string;
  value: string;
}

export const unescapeHtml = unescape;
export const escapeHtml = escape;

export function waitUntil(
  condition: () => boolean,
  interval: number,
  timeout?: number,
): Promise<void> {
  return new Promise((resolve, reject) => {
    let timeCount = Date.now();
    const intervalId = setInterval(() => {
      if (condition()) {
        clearInterval(intervalId);
        resolve();
      }

      if (timeout !== undefined && Date.now() - timeCount > timeout) {
        clearInterval(intervalId);
        reject();
      }
    }, interval);
  });
}

export function generateId(): string {
  let id: string[] = [];

  for (let i = 0; i < 4; i++) {
    id.push(Math.round(Math.random() * 100000000).toString(16));
  }

  return id.join("-");
}

export function inlineStyle(str: string): string {
  try {
    return btoa(str);
  } catch (e) {
    return "";
  }
}
export function parseInlineStyle(str: string): string {
  try {
    return atob(str);
  } catch (e) {
    return "";
  }
}

export function canShowOnThisTab(): boolean {
  if (document.hasFocus()) {
    return true;
  }

  if (TabAccountant.getInstance().isOnlyTab()) {
    return true;
  }

  return false;
}

export function populateObject(
  template: any,
  data?: any,
  defaultKeys?: string[],
  defaultValue?: any,
): any {
  let obj: any;

  if (typeof structuredClone === "function") {
    obj = structuredClone(template);
  } else {
    obj = JSON.parse(JSON.stringify(template));
  }

  if (defaultKeys && defaultKeys.length > 0 && defaultValue !== undefined) {
    for (let i = 0; i < defaultKeys.length; i++) {
      if (!obj.hasOwnProperty(defaultKeys[i])) {
        obj[defaultKeys[i]] = defaultValue;
      }
    }
  }

  if (data) {
    for (let key in data) {
      obj[key] = data[key];
    }
  }
  return obj;
}

export function replaceTemplate(
  template: string,
  data: { [key: string]: string },
): string {
  return template.replace(/{{{\s*(\w+)\s*}}}/g, (_, key) => {
    if (data.hasOwnProperty(key)) {
      return data[key];
    } else {
      return "{{{" + key + "}}}";
    }
  });
}

/*
 * Shortened version of replace template,
 * The CSS fragment should contain {{{selector}}} where the selector matches the targetted element
 * Use this to write in css method of the elements
 * Last param is the template
 */
export function writeCss(...args: string[]): string {
  if (args.length <= 1) {
    return "";
  }

  let template: string = args.pop() as string;
  let css = args.map((selector: string) =>
    replaceTemplate(template, { selector }),
  );

  return css.join("\n");
}

export function copyTextToClipboard(text: string): void {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

export interface MaskOptions {
  mask: string;
  patternsMap?: { [key: string]: RegExp };
}

export function applyMask(input: string, options: MaskOptions) {
  const { mask = "", patternsMap = {} } = options;
  let output = "";
  let index = 0;
  for (let i = 0; i < mask.length; i++) {
    const ch = mask[i];
    const pattern = patternsMap[ch];
    if (pattern) {
      const regex = new RegExp(pattern);
      const match = regex.exec(input.slice(index));
      if (match) {
        output += match[0];
        index += match[0].length;
      } else {
        break;
      }
    } else {
      output += ch;
      index++;
    }
  }
  return output;
}

/*
 * In: SVG string
 * Out: URL containing SVG
 * Example:
 * const myBackgroundImage = `background-image: url('${svgToURLEncodedBlob(mySvgString)}')`
 */
export function svgToURLEncodedBlob(svgString: string): string {
  const urlEncoded = encodeURIComponent(svgString)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  return `data:image/svg+xml,${urlEncoded}`;
}
