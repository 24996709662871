import {
  Attrs,
  MinzeAttrs,
  MinzeElement,
  EventListeners,
  Reactive,
} from "minze";
import { Font } from "@/constants";
import { Callback } from "@/modules/callback";

export interface BToggle {
  toggled: boolean;
  onClick: string;
  initialToggled: string;
  state: boolean;
}

export class BToggle extends MinzeElement {
  attrs: MinzeAttrs = ["on-click", "initial-toggled"];

  static get observedAttributes(): string[] {
    return ["initial-toggled"];
  }

  async onStart(): Promise<void> {
    this.state = this.initialToggled === "true";
    this.rerender();
  }

  afterAttributeChange(name: string, oldValue: string, value: string): void {
    if (name === "initial-toggled") {
      this.state = value === "true";
    }
    this.rerender();
  }

  onButtonClick(): void {
    this.state = !this.state;
    this.rerender();
    Callback.call(this.onClick, this.state);
  }

  html = () => `
    <button class="toggle" name="toggle" data-toggled="${this.state}"></button>
  `;

  css = () => `
    .toggle {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: flex-start;
      padding:3px;
      width: 52px;
      height: 30px;
      border-radius: 60px;
      border: 1px solid var(--brand-minuit, #1E007D);
      background: var(--brand-papier, #FFF);
      cursor: pointer;
      box-sizing: border-box;
    }

    .toggle::before {
      content: "";
      position: absolute;
      display: block;
      width: 22px;
      height: 22px;
      left: 4px;
      border-radius: 50%;
      background: var(--brand-minuit, #1E007D);
      transition: all 0.1s ease;
    }

    .toggle[data-toggled="true"]::before {
      left: 25px;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="toggle"]', "click", this.onButtonClick.bind(this)],
  ];
}
