import { EventListeners, MinzeElement, Reactive, Attrs } from "minze";
import { Font } from "@/constants";
import { Callback } from "@/modules/callback";
import ArrowDown from "iconoir/icons/solid/arrow-down-circle.svg?raw";
import ArrowUp from "iconoir/icons/solid/arrow-up-circle.svg?raw";

export interface BDrawer {
  onToggle: string;
  title: string;
  open: boolean;
}

export class BDrawer extends MinzeElement {
  open: boolean = false;
  attrs: Attrs = ["title", "on-toggle", "open"];
  static observedAttributes = ["on-toggle", "open"];

  onToggleClick() {
    this.open = !this.open;
    Callback.call(this.onToggle, this.open);
    this.rerender();
  }

  afterAttributeChange(name: string, value: any) {
    switch (name) {
      case "open":
        this.open = value;
        break;
      case "title":
        this.title = value;
        break;
      case "on-toggle":
        this.onToggle = value;
        break;
    }

    this.rerender();
  }

  html = () => {
    return `
      <div class="drawer-header ${this.open ? "open" : ""}" name="toggleDrawer">
        <div class="drawer-title">${this.title}</div>
        ${this.open ? ArrowUp : ArrowDown}
      </div>
      <div class="drawer-content ${this.open ? "open" : ""}">
        <slot name="b-drawer-content"></slot>
      </div>
    `;
  };

  css = () => {
    return `
      :host {
        padding: 0 16px;
        border-bottom: 1px solid rgba(30, 0, 125, 0.30);
        border-top: 1px solid rgba(30, 0, 125, 0.30);
        ${!this.open ? "border: none;" : ""}
        box-sizing: border-box;
      }
      .drawer-header {
        display: none;
      }

      .drawer-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding-left: 20px;
        padding-right: 15px;
        cursor: pointer;
      }

      .drawer-title {
        color: var(--brand-minuit, #1E007D);
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'clig' off, 'liga' off;

        /* D/boutons/principal */
        font-family: ${Font.family};
        font-size: 14px;
        font-style: normal;
        font-weight: ${Font.weight.medium};
        line-height: 40px;
      }

      .drawer-close {
      }

      .drawer-content {

      }

      .drawer-content.open {

      }

      [name="b-drawer-content"] {
        ${!this.open ? "display: none;" : ""}
        margin: 16px;
      }

    `;
  };

  eventListeners: EventListeners = [
    ['[name="toggleDrawer"]', "click", this.onToggleClick.bind(this)],
  ];
}
