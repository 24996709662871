type Callback = (...args: any[]) => void;

export class Emitter {
  private events: Record<string, Callback[]>;
  private onceEvents: Record<string, Callback[]>;

  constructor() {
    this.events = {};
    this.onceEvents = {};
  }

  on(eventName: string, callback: Callback): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  once(eventName: string, callback: Callback): void {
    if (!this.onceEvents[eventName]) {
      this.onceEvents[eventName] = [];
    }
    this.onceEvents[eventName].push(callback);
  }

  off(eventName: string, callback: Callback): void {
    if (!this.events[eventName]) {
      return;
    }
    const index = this.events[eventName].indexOf(callback);
    if (index !== -1) {
      this.events[eventName].splice(index, 1);
    }
  }

  emit(eventName: string, ...args: any[]): void {
    if (!this.events[eventName]) {
      return;
    }
    //console.log(`Fired: ${eventName}`);
    this.events[eventName].forEach((callback) => {
      try {
        callback(...args);
      } catch (e) {
        console.trace(e);
      }
    });

    if (this.onceEvents[eventName]) {
      this.onceEvents[eventName].forEach((callback) => {
        try {
          callback(...args);
        } catch (e) {
          console.trace(e);
        }
      });
      delete this.onceEvents[eventName];
    }
  }
}
