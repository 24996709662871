export class Locale {
  private static instance: Locale;
  public static getInstance(): Locale {
    if (!Locale.instance) {
      Locale.instance = new Locale();
    }
    return Locale.instance;
  }

  public static get language() {
    return Locale.getInstance().getLanguage();
  }

  public static getKey(key: string, params: any = {}) {
    return Locale.getInstance().getKey(key, params);
  }

  public static getPluralKey(key: string, count: number, params: any = {}) {
    return Locale.getInstance().getPluralKey(key, count, params);
  }

  public static setInstance(locale: Locale) {
    Locale.instance = locale;
  }

  private _locale: string = "fr";
  private _keySet: any = {};

  constructor() {
    this.intialize();
  }

  private intialize() {
    let htmlTag = document.querySelector("html");

    if (htmlTag) {
      this._locale = htmlTag.getAttribute("lang") || "fr";
    }
  }

  public getLanguage() {
    return this._locale;
  }

  public setLanguage(lang: string) {
    this._locale = lang;
  }
  public getKey(key: string, params: any = {}) {
    let value =
      this._keySet[`${this._locale}.${key}`] ||
      this._keySet[`fr.${key}`] ||
      key;

    for (let param in params) {
      value = value.replace(`{{${param}}}`, params[param]);
    }
    return value;
  }

  public getPluralKey(key: string, count: number, params: any = {}) {
    let pluralKey =
      key + "." + (count < 1 || count > 1 ? "plural" : "singular");
    return this.getKey(pluralKey, { ...params, count });
  }

  public registerKeySet(lang: string, name: string, keySet: any) {
    this._keySet = {
      ...(this._keySet || {}),
      ...this.flattenKeySet(keySet, `${lang}.${name}.`),
    };
  }

  private flattenKeySet(keySet: any, prefix: string = "") {
    let result: any = {};
    for (let key in keySet) {
      if (typeof keySet[key] === "object") {
        Object.assign(
          result,
          this.flattenKeySet(keySet[key], prefix + key + "."),
        );
      } else {
        result[prefix + key] = keySet[key];
      }
    }
    return result;
  }
}
