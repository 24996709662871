import { MinzeElement, type Attrs, type Reactive, EventListeners } from "minze";
import logo from "@/constants/plain-svgs/logo";

export interface SscLogo {}

export class SscLogo extends MinzeElement {
  attrs: Attrs = [];
  reactive: Reactive = [];
  eventListeners: EventListeners = [];
  static observedAttributes = [];

  async onReady(): Promise<void> {}

  onDestroy(): void {}

  html = () => logo;

  css = () => {
    return `
      :host {
      }
    `;
  };
}
