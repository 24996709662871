import Minze, { Attrs, MinzeElement, Reactive } from "minze";
import { Font } from "@/constants";
import Warning from "iconoir/icons/solid/warning-triangle.svg?raw";

export interface BError {
  error: string | boolean;
}

export class BError extends MinzeElement {
  attrs: Attrs = [["error", false]];

  static observedAttributes = ["error"];

  html = () => {
    const showError = typeof this.error === "string" && this.error.length > 0;

    if (!this.error) {
      return ``;
    }

    if (!showError && this.error) {
      return `
        <div>
          <div class="error-label">
            <div class="icon">${Warning}</div>
          </div>
        </div>
      `;
    }

    return `
      <div>
        <div class="error-label">
          <div class="icon">${Warning}</div>
          <span>${this.error}</span>
        </div>
      </div>
    `;
  };
  css = () => {
    return `
    :host {
      width: 100%;
      position: relative;
      box-sizing: border-box;
      font-family: ${Font.family};
    }
    .error {
      background: #FFEBD7;
      border-bottom: 3px solid #FF4600;
    }
    .error-label {
      display: flex;
      margin-top: 4px;
      min-height: 24px;
      font-family: ${Font.family};
      color: #D00;
      font-size: 12px;
      font-weight: ${Font.weight.bold};
      line-height: 24px;
    }

    .error-label span {
      display: inline-block;
      vertical-align: top;
    }

    .icon {
      flex: 0 0 24px;
      display: inline-block;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      vertical-align: top;
    }

    .error-label b-warning-icon {
      display: inline-block;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      vertical-align: top;
    }

  `;
  };
}
