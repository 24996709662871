
import Minze, { MinzeElement, Reactive, EventListeners, Attrs } from "minze";
import {
  EMAIL_REGEX,
  escapeHtml,
  unescapeHtml,
  waitUntil,
  writeCss,
} from "@/modules/utils";
import { MainModule } from "@/modules/main";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";
import GoogleIcon from "iconoir/icons/regular/google.svg?raw";
import FacebookIcon from "iconoir/icons/regular/facebook-tag.svg?raw";
import { config, Style } from "@/constants";

const projectName = config.projectName;

Locale.getInstance().registerKeySet("fr", "sscforgotpassword", {
  welcome: "Bienvenue chez",
  company: "Succès Scolaire",
  title: "Connexion",
  resetPassword: "Envoyer un lien de réinitialisation",
  email: "Courriel",
  emailPlaceholder: "Entrez votre adresse courriel",
  password: "Mot de passe",
  passwordPlaceholder: "Entrez votre mot de passe",
  errors: {
    invalidEmail: "Adresse courriel invalide",
    general: "Erreur de connexion",
    emailRequired: "Ce champ est requis",
    noAccount: "Aucun compte n'est associé à cette adresse courriel",
    apiError: "Erreur de connexion",
  },
  providers: {
    google: "Se connecter avec Google",
    facebook: "Se connecter avec Facebook",
  },
});

Locale.getInstance().registerKeySet("en", "sscforgotpassword", {
  welcome: "Welcome to",
  company: "Succès Scolaire",
  title: "Login",
  resetPassword: "Send reset link",
  email: "E-mail",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  forgotPassword: "<u>Forgot your password?</u>",
  noAccountYet: "Still don't have an account? <u>Sign up</u>",
  errors: {
    invalidEmail: "Invalid email",
    general: "Login error",
    emailRequired: "This field is required",
    noAccount: "No account is associated with this email address",
    apiError: "Login error",
  },
  providers: {
    google: "Login with Google",
    facebook: "Login with Facebook",
  },
});

export interface SscForgotPassword {
  email?: string;
  bgImageUrl?: string;
  loading?: boolean;
}

export class SscForgotPassword extends MinzeElement {
  attrs: Attrs = [
    ["data-api", "/forgot_password"]
  ];

  reactive: Reactive = [
    ["loading", false],
    ["email", ""],
    [
      "bgImageUrl",
      "https://storage.googleapis.com/successcolaire.appspot.com/public/account_screen_background.png",
    ],
  ];

  private errors: any = {
    email: false,
    general: false,
  };

  async onReady(): Promise<void> {
    this.email = localStorage.getItem("rememberedEmail") || "";
    this.loading = false;
  }

  onDestroy() {}

  onForgotPassword = async () => {
    for (const key in this.errors) {
      this.errors[key] = false;
    }
    let isUsername = false;
    let error: any = null;
    const emailElement: any = this.select('[name="email"]');
    const email = unescapeHtml(emailElement?.value);
    this.email = email;

    const mainModule = MainModule.getInstance();
    if (!email) {
      this.errors.email = Locale.getKey("sscforgotpassword.errors.emailRequired");
    } else if (!EMAIL_REGEX.test(email)) {
      isUsername = true;
    }

    let hasError = false;

    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
      }
    }

    if (hasError) {
      this.rerender();
      return;
    }

    this.loading = true;

    try {
      let form = new FormData();
      form.append('email', email)
      form.append('isUsername', isUsername)
      const response = fetch(this.dataApi, {
        method: "POST",
        body: form,
      })

      this.loading = false;
      window.location.href = "/ecole/motdepasse_oublie_envoye";
    } catch (e) {
      error = e;
      this.loading = false;
      if (error.code === "auth/user-not-found") {
        this.errors.email = Locale.getKey("sscforgotpassword.errors.noAccount");
      } else {
        this.errors.email = true;
        this.errors.general = Locale.getKey("sscforgotpassword.errors.general");
      }
    }

    this.rerender();
  };

  html = () => {
    return `
      <ssc-modal
        background-image-url="${this.bgImageUrl}"
        narrow="true"
        modal-title="">
        <div slot="b-modal-overlay" stlye="z-index: 1">
          <ssc-logo style="z-index:1"></ssc-logo>
        </div>
        <div slot="b-modal-content" class="form">
          <div class="left">
          </div>
          <div class="right">
            <div class="title">
              <h3>${Locale.getKey("sscforgotpassword.welcome")}</h3>
              <h1>${Locale.getKey("sscforgotpassword.company")}</h1>
            </div>
            <div class="title-spacer"></div>
            <ssc-input error="${this.errors.email}" name="email" placeholder="${Locale.getKey("sscforgotpassword.emailPlaceholder")}" default-value="${escapeHtml(this.email)}" label="${Locale.getKey("sscforgotpassword.email")}"></ssc-input>
            ${this.errors.general ? `<b-error error="${this.errors.general}"></b-error>` : ``}
            <b-button name="forgot_password" class="${this.loading ? "loading" : ""}" preset="sscPrimary">
              ${Locale.getKey("sscforgotpassword.resetPassword")}
            </b-button>
          </div>
        </div>
        <ssc-footer slot="b-modal-footer">
        </ssc-footer>
      </ssc-modal>
    `;
  };

  css = () => `
    :host {

    }
    .switch-to-signup {
      cursor: pointer;
    }


    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #000;
    }

    a>u:hover {
      color: #dd1d1d;
    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      padding: 37px 94px 46px 68px;
      gap: 20px;
    }


    .left {
      background: #FECA36;
      background-image: url('https://storage.googleapis.com/successcolaire.appspot.com/public/repetitive_dot.png');
      background-size: 22px;
      flex: 1;
      box-sizing: border-box;
      border: 30px solid #FECA36;
      max-width: 200px;
    }

    h3 {
      color: #0D465B;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 200% */
    }

    h1 {
      color: #0D465B;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }

    .title-spacer {
      height: 40px;
    }
    ssc-logo {
      margin: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }


    ${writeCss(Style.link.template, "a[href], a[href]:visited, a[href]:link")}

    .loading {
      pointer-events: none;
      opacity: 0.5;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="forgot_password"]', "click", this.onForgotPassword.bind(this)],
  ];
}
