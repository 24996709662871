import Minze, { MinzeElement, Reactive, EventListeners } from "minze";
import { EMAIL_REGEX, escapeHtml, unescapeHtml } from "@/modules/utils";
import { MainModule } from "@/modules/main";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";
import GoogleIcon from "iconoir/icons/regular/google.svg?raw";
import FacebookIcon from "iconoir/icons/regular/facebook-tag.svg?raw";

Locale.getInstance().registerKeySet("fr", "login", {
  title: "Connexion",
  login: "Me connecter",
  email: "Courriel",
  emailPlaceholder: "Entrez votre adresse courriel",
  password: "Mot de passe",
  passwordPlaceholder: "Entrez votre mot de passe",
  forgotPassword: "<u>Mot de passe oublié?</u>",
  rememberMe: "Se souvenir de moi",
  noAccountYet: "Pas encore de compte? <u>M'inscrire</u>",
  errors: {
    invalidEmail: "Adresse courriel invalide",
    invalidPassword: "Mot de passe invalide",
    general: "Erreur de connexion",
    emailRequired: "Ce champ est requis",
    passwordRequired: "Ce champ est requis",
    noAccount: "Aucun compte n'est associé à cette adresse courriel",
  },
  providers: {
    google: "Se connecter avec Google",
    facebook: "Se connecter avec Facebook",
  },
});

Locale.getInstance().registerKeySet("en", "login", {
  title: "Login",
  login: "Sign in",
  email: "E-mail",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  forgotPassword: "<u>Forgot your password?</u>",
  rememberMe: "Remember me",
  noAccountYet: "Still don't have an account? <u>Sign up</u>",
  errors: {
    invalidEmail: "Invalid email",
    invalidPassword: "Invalid password",
    general: "Login error",
    emailRequired: "This field is required",
    passwordRequired: "This field is required",
    noAccount: "No account is associated with this email address",
  },
  providers: {
    google: "Login with Google",
    facebook: "Login with Facebook",
  },
});

export interface BLogin {
  email?: string;
  password?: string;
}

export class BLogin extends MinzeElement {
  reactive: Reactive = [
    ["email", ""],
    ["password", ""],
  ];

  private errors: any = {
    email: false,
    password: false,
    general: false,
  };

  onReady(): void | Promise<void> {
    this.email = localStorage.getItem("rememberedEmail") || "";
  }

  onLogin = async () => {
    for (const key in this.errors) {
      this.errors[key] = false;
    }
    let error: any = null;
    const emailElement: any = this.select('[name="email"]');
    const email = unescapeHtml(emailElement?.value);
    this.email = email;

    const passwordElement: any = this.select('[name="password"]');
    const password = unescapeHtml(passwordElement?.value);
    this.password = password;

    const rememberMeElement: any = this.select('[name="rememberMe"]');
    const rememberMe = rememberMeElement?.getAttribute("checked");
    const bRememberMe = rememberMe === "true" || rememberMe === true;

    const mainModule = MainModule.getInstance();
    if (!email) {
      this.errors.email = Locale.getKey("login.errors.emailRequired");
    } else if (!EMAIL_REGEX.test(email)) {
      this.errors.email = Locale.getKey("login.errors.invalidEmail");
    }

    if (!password) {
      this.errors.password = Locale.getKey("login.errors.passwordRequired");
    }

    let hasError = false;

    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
      }
    }

    if (hasError) {
      this.rerender();
      return;
    }

    if (email && password) {
      this.email = email;
      this.password = password;

      if (bRememberMe) {
        localStorage.setItem("rememberedEmail", email);
      } else {
        localStorage.removeItem("rememberedEmail");
      }

      mainModule.analyticsTrack("login", { method: "Email" });
      error = (await mainModule.getAuth().login(email, password)) as any;
    }

    if (error) {
      if (error.code === "auth/user-not-found") {
        this.errors.email = Locale.getKey("login.errors.noAccount");
      } else if (error.code === "auth/wrong-password") {
        this.errors.password = Locale.getKey("login.errors.invalidPassword");
      } else {
        this.errors.password = true;
        this.errors.email = true;
        this.errors.general = Locale.getKey("login.errors.general");
      }
    }

    this.rerender();
  };

  onSignupWithGoogle() {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("login", { method: "Google" });
    mainModule.getAuth().callGoogleSignIn();
  }

  onSignupWithFacebook() {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("login", { method: "Facebook" });
    mainModule.getAuth().callFacebookSignIn();
  }

  html = () => {
    let rememberMe = !!localStorage.getItem("rememberedEmail") as boolean;

    return `
      <b-modal narrow="true" modal-title="${Locale.getKey("login.title")}">
        <div slot="b-modal-top">
          <b-button class="link-back">
            ${ChevronLeftIcon}
            ${Locale.getKey("general.previous")}
          </b-button>
        </div>
        <div slot="b-modal-content" class="form">
          <a class="no-account-yet" href="${MainModule.getInstance().getRouting().getRoute("signup")}">
            ${Locale.getKey("login.noAccountYet")}
          </a>
          <b-input error="${this.errors.email}" name="email" placeholder="${Locale.getKey("login.emailPlaceholder")}" default-value="${escapeHtml(this.email)}" label="${Locale.getKey("login.email")}"></b-input>
          <b-input error="${this.errors.password}" name="password" type="password" placeholder="${Locale.getKey("login.passwordPlaceholder")}" default-value="${escapeHtml(this.password)}" label="${Locale.getKey("login.password")}"></b-input>
          <a href="${MainModule.getInstance().getRouting().getRoute("forgotPassword")}" class="forgot-password">${Locale.getKey("login.forgotPassword")}</a>
          <b-checkbox name="rememberMe"
            default-value="${rememberMe}" >
            ${Locale.getKey("login.rememberMe")}
            </b-checkbox>
          ${this.errors.general ? `<b-error error="${this.errors.general}"></b-error>` : ``}
          <b-button name="login">
            ${Locale.getKey("login.login")}
          </b-button>
          <div class="or">
            <div class="or-line"></div>
            <div>
              ${Locale.getKey("general.or")}
            </div>
            <div class="or-line"></div>
          </div>
          <b-button class="google-sign-provider-button">
              ${Locale.getKey("login.providers.google")}
              ${GoogleIcon}
          </b-button>
          <b-button class="facebook-sign-provider-button">
              ${Locale.getKey("login.providers.facebook")}
              ${FacebookIcon}
          </b-button>
        </div>
      </b-modal>
    `;
  };

  css = () => `
    :host {

    }
    .switch-to-signup {
      cursor: pointer;
    }

    .forgot-password-link {
      display: flex;
      justify-content: flex-end;

    }

    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #000;
    }

    a>u:hover {
      color: #dd1d1d;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="login"]', "click", this.onLogin.bind(this)],
    [
      ".google-sign-provider-button",
      "click",
      this.onSignupWithGoogle.bind(this),
    ],
    [
      ".facebook-sign-provider-button",
      "click",
      this.onSignupWithFacebook.bind(this),
    ],
  ];
}
