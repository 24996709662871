import Minze, { MinzeElement, Reactive, EventListeners , type Attrs} from "minze";
import { config } from "@/constants/config";
import { MainModule } from "@/modules/main";
import axios from 'redaxios';
const projectName = config.projectName;


export interface SscLogout {
  dataRedirectUrl: string;
}

export class SscLogout extends MinzeElement {
  attrs: Attrs = [
    ["data-redirect-url", ""]
  ];
  
  eventListeners: EventListeners = [
    [this, "click", this.onClick.bind(this)]
  ]
  
  async onClick() {
    const mainModule = MainModule.getInstance();
    const auth = mainModule.getAuth();
    if (auth.isLoggedIn()) {
      await auth.logout();
    }
    window.location.href = this.dataRedirectUrl;
  }
  
  html = () => `
    <button>
      <slot></slot>
    </button>
  `;
  
  css = () => `
    :host {
      cursor: pointer;
    }
    button {
      cursor: pointer;
      background: none;
      border: none;
      color: inherit;
      font: inherit;
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
    }
  `;
}