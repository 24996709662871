import { Locale } from "../modules/locale";

Locale.getInstance().registerKeySet("en", "general.months", {
  long: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  short: {
    1: "Jan.",
    2: "Feb.",
    3: "Mar.",
    4: "Apr.",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug.",
    9: "Sept.",
    10: "Oct.",
    11: "Nov.",
    12: "Dec.",
  },
});

Locale.getInstance().registerKeySet("fr", "general.months", {
  long: {
    1: "janvier",
    2: "février",
    3: "mars",
    4: "avril",
    5: "mai",
    6: "juin",
    7: "juillet",
    8: "août",
    9: "septembre",
    10: "octobre",
    11: "novembre",
    12: "décembre",
  },
  short: {
    1: "jan.",
    2: "fév.",
    3: "mars.",
    4: "avr.",
    5: "mai.",
    6: "juin",
    7: "juil",
    8: "août",
    9: "sept.",
    10: "oct.",
    11: "nov.",
    12: "déc.",
  },
});
