import Minze, { MinzeElement, Reactive, EventListeners, Attrs } from "minze";
import {
  EMAIL_REGEX,
  escapeHtml,
  unescapeHtml,
  waitUntil,
  writeCss,
} from "@/modules/utils";
import { MainModule } from "@/modules/main";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";
import GoogleIcon from "iconoir/icons/regular/google.svg?raw";
import FacebookIcon from "iconoir/icons/regular/facebook-tag.svg?raw";
import { config, Style } from "@/constants";

const projectName = config.projectName;

Locale.getInstance().registerKeySet("fr", "sscpwreset", {
  welcome: "Bienvenue chez",
  company: "Succès Scolaire",
  title: "Connexion",
  resetPassword: "Changez votre mot de passe",
  password: "Mot de passe",
  passwordPlaceholder: "Entrez votre mot de passe",
  passwordConfirm: "Confirmez le mot de passe",
  passwordConfirmPlaceholder: "Confirmez votre mot de passe",
  errors: {
    passwordRequired: "Ce champ est requis",
    passwordConfirmRequired: "Ce champ est requis",
    passwordMismatch: "Les mots de passe ne correspondent pas",
    passwordTooShort: "Le mot de passe doit contenir au moins 8 caractères",
    passwordNoNumber: "Le mot de passe doit contenir au moins un chiffre",
    passwordNoSpecialChar: "Le mot de passe doit contenir au moins un caractère spécial",
    passwordNoUppercase: "Le mot de passe doit contenir au moins une lettre majuscule",
    passwordNoLowercase: "Le mot de passe doit contenir au moins une lettre minuscule",
    passwordsDontMatch: "Les mots de passe ne correspondent pas",
    general: "Erreur de connexion",
    noAccount: "Aucun compte n'est associé à cette adresse courriel",
    apiError: "Erreur de connexion",
  },
  providers: {
    google: "Se connecter avec Google",
    facebook: "Se connecter avec Facebook",
  },
});

Locale.getInstance().registerKeySet("en", "sscpwreset", {
  welcome: "Welcome to",
  company: "Succès Scolaire",
  title: "Login",
  resetPassword: "Change your password",
  email: "E-mail",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  forgotPassword: "<u>Forgot your password?</u>",
  noAccountYet: "Still don't have an account? <u>Sign up</u>",
  errors: {
    passwordRequired: "This field is required",
    passwordConfirmRequired: "This field is required",
    passwordMismatch: "Passwords do not match",
    passwordTooShort: "Password must be at least 8 characters long",
    passwordNoNumber: "Password must contain at least one number",
    passwordNoSpecialChar: "Password must contain at least one special character",
    passwordNoUppercase: "Password must contain at least one uppercase letter",
    passwordNoLowercase: "Password must contain at least one lowercase letter",
    passwordsDontMatch: "Passwords do not match",
    general: "Login error",
    emailRequired: "This field is required",
    noAccount: "No account is associated with this email address",
    apiError: "Login error",
  },
  providers: {
    google: "Login with Google",
    facebook: "Login with Facebook",
  },
});

export interface SscPasswordReset {
  password?: string;
  passwordConfirm?: string;
  passwordResetCode?: string;
  bgImageUrl?: string;
  loading?: boolean;
}

export class SscPasswordReset extends MinzeElement {
  attrs: Attrs = [
    ["data-api", "/reset_password"]
  ];

  reactive: Reactive = [
    ["loading", false],
    ["password", ""],
    ["passwordConfirm", ""],
    ["passwordResetCode", ""],
    [
      "bgImageUrl",
      "https://storage.googleapis.com/successcolaire.appspot.com/public/account_screen_background.png",
    ],
  ];

  private errors: any = {
    email: false,
    general: false,
  };

  async onReady(): Promise<void> {
    this.loading = false;

    let queryParams = new URLSearchParams(window.location.search);
    this.passwordResetCode = queryParams.get("password_reset_code");
    this.rerender();
  }

  onDestroy() {}

  //password standard 1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters
  onResetPassword = async () => {
    this.loading = true;
    for (const key in this.errors) {
      this.errors[key] = false;
    }
    let error: any = null;
    const passwordElement: any = this.select('[name="password"]');
    const password = unescapeHtml(passwordElement?.value);
    this.password = password;
    const confirmPasswordElement: any = this.select('[name="passwordConfirm"]');
    const confirmPassword = unescapeHtml(confirmPasswordElement?.value);
    this.passwordConfirm = confirmPassword;

    const mainModule = MainModule.getInstance();

    if (!password) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordRequired");
    }

    if (password.length < 8) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordTooShort");
    }

    if (!/[A-Z]/.test(password)) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordNoUppercase");
    }

    if (!/[a-z]/.test(password)) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordNoLowercase");
    }

    if (!/[0-9]/.test(password)) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordNoNumber");
    }

    if (!/[!@#$%^&*]/.test(password)) {
      this.errors.password = Locale.getKey("sscpwreset.errors.passwordNoSpecialChar");
    }

    if (!confirmPassword) {
      this.errors.passwordConfirm = Locale.getKey("sscpwreset.errors.passwordConfirmRequired");
    }
    if (password != confirmPassword)
      this.errors.passwordConfirm = Locale.getKey("sscpwreset.errors.passwordsDontMatch");


    let hasError = false;

    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
      }
    }

    if (hasError) {
      this.loading = false;
      this.rerender();
      return;
    }

    try {
      let form = new FormData();
      form.append("password", password);
      form.append("confirmPassword", confirmPassword);
      form.append("passwordResetCode", this.passwordResetCode);
      const response = fetch(this.dataApi, {
        method: "POST",
        body: form
      })

      this.loading = false;
      window.location.href = "/ecole/entree";
    } catch (e) {
      error = e;
      this.loading = false;
      if (error.code === "auth/user-not-found") {
        this.errors.email = Locale.getKey("sscpwreset.errors.noAccount");
      } else {
        this.errors.email = true;
        this.errors.general = Locale.getKey("sscpwreset.errors.general");
      }
    }

    this.rerender();
  };

  html = () => {
    return `
      <ssc-modal
        background-image-url="${this.bgImageUrl}"
        narrow="true"
        modal-title="">
        <div slot="b-modal-overlay" stlye="z-index: 1">
          <ssc-logo style="z-index:1"></ssc-logo>
        </div>
        <div slot="b-modal-content" class="form">
          <div class="left">
          </div>
          <div class="right">
            <div class="title">
              <h3>${Locale.getKey("sscpwreset.welcome")}</h3>
              <h1>${Locale.getKey("sscpwreset.company")}</h1>
            </div>
            <div class="title-spacer"></div>
            <ssc-input error="${this.errors.password}" name="password" type="password" placeholder="${Locale.getKey("sscpwreset.passwordPlaceholder")}" default-value="${escapeHtml(this.password)}" label="${Locale.getKey("sscpwreset.password")}"></ssc-input>
            <ssc-input error="${this.errors.passwordConfirm}" name="passwordConfirm" type="password" placeholder="${Locale.getKey("sscpwreset.passwordConfirmPlaceholder")}" default-value="${escapeHtml(this.passwordConfirm)}" label="${Locale.getKey("sscpwreset.passwordConfirm")}"></ssc-input>
            ${this.errors.general ? `<b-error error="${this.errors.general}"></b-error>` : ``}
            <b-button name="reset_password" class="${this.loading ? "loading" : ""}" preset="sscPrimary">
              ${Locale.getKey("sscpwreset.resetPassword")}
            </b-button>
          </div>
        </div>
        <ssc-footer slot="b-modal-footer">
        </ssc-footer>
      </ssc-modal>
    `;
  };

  css = () => `
    :host {

    }
    .switch-to-signup {
      cursor: pointer;
    }


    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #000;
    }

    a>u:hover {
      color: #dd1d1d;
    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      padding: 37px 94px 46px 68px;
      gap: 20px;
    }


    .left {
      background: #FECA36;
      background-image: url('https://storage.googleapis.com/successcolaire.appspot.com/public/repetitive_dot.png');
      background-size: 22px;
      flex: 1;
      box-sizing: border-box;
      border: 30px solid #FECA36;
      max-width: 200px;
    }

    h3 {
      color: #0D465B;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 200% */
    }

    h1 {
      color: #0D465B;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }

    .title-spacer {
      height: 40px;
    }
    ssc-logo {
      margin: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }


    ${writeCss(Style.link.template, "a[href], a[href]:visited, a[href]:link")}

    .loading {
      pointer-events: none;
      opacity: 0.5;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="reset_password"]', "click", this.onResetPassword.bind(this)],
  ];
}
