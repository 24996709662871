import { MinzeElement, type Attrs, type Reactive, EventListeners } from "minze"
import { Locale } from "@/modules/locale"

Locale.getInstance().registerKeySet("fr", "languageSelector", {
  en: "EN",
  fr: "FR"
});

Locale.getInstance().registerKeySet("en", "languageSelector", {
  en: "EN",
  fr: "FR"
});


export interface SscLanguageSelector {

}

export class SscLanguageSelector extends MinzeElement {
  attrs: Attrs = [];
  reactive: Reactive = [];
  eventListeners: EventListeners = [
    ["select", "change", this.onLanguageChange.bind(this)]
  ];
  static observedAttributes = [];
  languageOptions: string[] = ["en", "fr"];

  async onReady(): Promise<void> {

  }

  onLanguageChange(e: Event) {
    const target = e.target as HTMLSelectElement;
    const lang = target.value;
    Locale.getInstance().setLanguage(lang);
  }

  onDestroy(): void {}

  renderSelect() {
    return `
      <select>
        ${this.languageOptions.map((lang) => {
          return `<option value="${lang}">${Locale.getKey(`languageSelector.${lang}`)}</option>`
        }).join("")}
      </select>
    `
  }

  html = () => {
    return `
      ${this.renderSelect()}
    `;
  };

  css = () => {
    return `
      :host {
      }
    `;
  };
}
