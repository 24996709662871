import { Storage } from "@/modules/storage";
import { generateId } from "@/modules/utils";
import { config } from "@/constants/config";

const projectName = config.projectName;

// in milliseconds
const TAB_TIMEOUT = 750;
const TAB_UPDATE_INTERVAL = 450;
const TAB_UPDATE_JITTER = 50;

export class TabAccountant {
  private static instance: TabAccountant;
  public static getInstance(): TabAccountant {
    if (!TabAccountant.instance) {
      TabAccountant.instance = new TabAccountant();
    }
    return TabAccountant.instance;
  }

  private tabId: string = generateId();
  private intervalId: any;
  private _storage: Storage = new Storage();

  constructor() {
    this.init();
  }

  init() {
    this.update();
    this.intervalId = setInterval(
      () => {
        this.update();
      },
      TAB_UPDATE_INTERVAL + Math.random() * TAB_UPDATE_JITTER,
    );
  }

  private update() {
    let instances = this._storage.getItem(`${projectName}_TAB_INSTANCES`);
    let instancesObject: any = {};

    if (instances) {
      instancesObject = instances;
    }

    for (let tabId in instancesObject) {
      let tid = tabId as string;
      let tabTime = instancesObject[tid];
      if (Date.now() - tabTime > TAB_TIMEOUT) {
        delete instancesObject[tid];
      }
    }

    instancesObject[this.tabId] = Date.now();

    this._storage.setItem(`${projectName}_TAB_INSTANCES`, instancesObject);
  }

  public getTabCount(): number {
    let instances = this._storage.getItem(`${projectName}_TAB_INSTANCES`);
    let instancesObject = {};

    if (instances) {
      instancesObject = instances;
    }

    return Object.keys(instancesObject).length;
  }

  public isOnlyTab(): boolean {
    return this.getTabCount() === 1;
  }
}
