import { MinzeElement, Reactive, EventListeners } from "minze";
import { MainModule } from "@/modules/main";
import { Routing } from "@/modules/routing";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  escapeHtml,
  unescapeHtml,
} from "@/modules/utils";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";
import GoogleIcon from "iconoir/icons/regular/google.svg?raw";
import FacebookIcon from "iconoir/icons/regular/facebook-tag.svg?raw";

Locale.getInstance().registerKeySet("fr", "signup", {
  title: "Créer un compte",
  firstName: "Prénom",
  firstNamePlaceholder: "Votre prénom",
  lastName: "Nom",
  lastNamePlaceholder: "Entrez votre nom",
  email: "Courriel",
  emailPlaceholder: "Entrez votre courriel",
  password: "Mot de passe",
  passwordTooltip: {
    title:
      "Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial",
    point1: "8 caractères minimum",
    point2: "Au moins une majuscule",
    point3: "Au moins un chiffre",
    point4: "Au moins un caractère spécial",
  },
  passwordPlaceholder: "Entrez votre mot de passe",
  confirmPolicy:
    "J'accepte les conditions d'utilisation et la politique de confidentialité.",
  submit: "S'abonner",
  alreadyAccount: "Déjà un compte? <u>Se connecter</u>",
  acceptNotifications:
    "J'accepte de recevoir des notifications de bbox.digital",
  subscribeNewsletter: "Je souhaite m'abonner à l'infolettre de bbox.digital",
  errors: {
    firstName: "Veuillez entrer votre prénom",
    lastName: "Votre nom",
    email: "Veuillez entrer votre adresse e-mail",
    emailInvalid: "Courriel invalide",
    password: "Veuillez entrer un mot de passe",
    passwordNotMatch: "Les mots de passe ne correspondent pas",
    passwordCriterias:
      "Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial",
    passwordLength: "Le mot de passe doit contenir au moins 8 caractères",
    general: "Erreur lors de la création du compte",
    confirmPolicy:
      "Vous devez accepter les conditions d'utilisation et la politique de confidentialité.",
    emailAlreadyExists: "Cette adresse e-mail est déjà utilisée",
    emailAlreadyExistsWithProvider: {
      "google.com": "Cette adresse e-mail est déjà utilisée avec Google",
      "facebook.com": "Cette adresse e-mail est déjà utilisée avec Facebook",
      password: "Cette adresse e-mail est déjà utilisée avec un mot de passe",
    },
  },
  providers: {
    google: "Continuer avec Google",
    facebook: "Continuer avec Facebook",
  },
});

Locale.getInstance().registerKeySet("en", "signup", {
  title: "Create an account",
  firstName: "First Name",
  firstNamePlaceholder: "First name",
  lastName: "Last Name",
  lastNamePlaceholder: "Enter your last name",
  email: "Email Address",
  emailPlaceholder: "Enter your email address",
  password: "Password",
  passwordTooltip: {
    title:
      "Your password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character",
    point1: "At least 8 characters",
    point2: "At least one capital",
    point3: "At least one number",
    point4: "At least one special character",
  },
  passwordPlaceholder: "Enter your password",
  confirmPolicy: "I accept the terms of use and privacy policy.",
  submit: "Sign up",
  alreadyAccount: "Already have an account? <u>Sign in</u>",
  acceptNotifications: "I accept to receive notifications from bbox.digital",
  subscribeNewsletter: "I wish to subscribe to bbox.digital newsletter",
  errors: {
    firstName: "Please enter your first name",
    lastName: "Last name",
    email: "Please enter your email address",
    emailInvalid: "Invalid e-mail",
    password: "Please enter a password",
    passwordNotMatch: "Passwords do not match",
    passwordCriterias:
      "Your password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character",
    passwordLength: "Password must be at least 8 characters long",
    general: "Error while creating account",
    confirmPolicy: "You must accept the terms of use and privacy policy.",
    emailAlreadyExists: "This email address is already used",
    emailAlreadyExistsWithProvider: {
      "google.com": "This email address is already used with Google",
      "facebook.com": "This email address is already used with Facebook",
      password: "This email address is already used with a password",
    },
  },
  providers: {
    google: "Continue with Google",
    facebook: "Continue with Facebook",
  },
});

export interface BSignup {}

export class BSignup extends MinzeElement {
  onReady(): void | Promise<void> {}

  errors: any = {
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    general: false,
  };

  confirmPolicy: boolean = false;
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  password: string = "";
  passwordConfirm: string = "";
  acceptNotifications: boolean = false;
  subscribeNewsletter: boolean = false;

  async onSignup(): Promise<void> {
    const mainModule = MainModule.getInstance();
    const confirmElement: any = this.select('[name="confirmPolicy"]');
    const confirmVal = confirmElement?.getAttribute("checked");
    const bConfirm: boolean = confirmVal === "true" || confirmVal === true;

    this.confirmPolicy = bConfirm;
    const firstNameElement: any = this.select('[name="firstName"]');
    const firstName = unescapeHtml(firstNameElement?.value);
    this.firstName = firstName;

    const lastNameElement: any = this.select('[name="lastName"]');
    const lastName = unescapeHtml(lastNameElement?.value);
    this.lastName = lastName;

    const emailElement: any = this.select('[name="email"]');
    const email = unescapeHtml(emailElement?.value);
    this.email = email;

    const passwordElement: any = this.select('[name="password"]');
    const password = unescapeHtml(passwordElement?.value);
    this.password = password;

    const acceptNotificationsElement: any = this.select(
      '[name="confirmNotifications"]',
    );
    const acceptNotificationsVal =
      acceptNotificationsElement?.getAttribute("checked");
    const bAcceptNotifications: boolean =
      acceptNotificationsVal === "true" || acceptNotificationsVal === true;
    this.acceptNotifications = bAcceptNotifications;

    const subscribeNewsletterElement: any = this.select(
      '[name="confirmNewsletter"]',
    );
    const subscribeNewsletterVal =
      subscribeNewsletterElement?.getAttribute("checked");
    const bSubscribeNewsletter: boolean =
      subscribeNewsletterVal === "true" || subscribeNewsletterVal === true;
    this.subscribeNewsletter = bSubscribeNewsletter;

    for (const key in this.errors) {
      this.errors[key] = false;
    }
    if (!bConfirm) {
      this.errors.general = Locale.getKey("signup.errors.confirmPolicy");
    }

    if (!firstName) {
      this.errors.firstName = Locale.getKey("signup.errors.firstName");
    }

    if (!lastName) {
      this.errors.lastName = Locale.getKey("signup.errors.lastName");
    }

    if (!email) {
      this.errors.email = Locale.getKey("signup.errors.email");
    }

    if (!password) {
      this.errors.password = Locale.getKey("signup.errors.password");
    }

    if (password && password.length < 8) {
      this.errors.password = Locale.getKey("signup.errors.passwordLength");
    }

    if (password && !PASSWORD_REGEX.test(password)) {
      this.errors.password = Locale.getKey("signup.errors.passwordCriterias");
    }

    if (email && !EMAIL_REGEX.test(email)) {
      this.errors.email = Locale.getKey("signup.errors.emailInvalid");
    }

    let hasErrors = false;
    for (const key in this.errors) {
      if (this.errors[key]) {
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.rerender();
      return;
    }

    if (firstName && email && password) {
      mainModule.analyticsTrack("sign-up", {
        method: "Email",
      });
      let result = await mainModule.getAuth().createUser({
        password: password,
        email: email,
        lang: mainModule.getLang(),
        firstName: firstName,
        lastName: lastName ?? "",
        acceptNotifications: bAcceptNotifications,
        subscribeNewsletter: bSubscribeNewsletter,
        policyAccepted: bConfirm,
      });

      if (result?.success) {
        await mainModule.getAuth().login(email, password);
      } else {
        if (result?.error?.status === "user already exists") {
          if (result?.error?.provider) {
            this.errors.email = Locale.getKey(
              `signup.errors.emailAlreadyExistsWithProvider.${result?.error?.provider}`,
            );
          } else {
            this.errors.email = Locale.getKey(
              "signup.errors.emailAlreadyExists",
            );
          }
        } else {
          this.errors.general = Locale.getKey("signup.errors.general");
        }
        this.rerender();
      }
    }
  }

  async onSignupWithGoogle(): Promise<void> {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("sign_up", {
      method: "Google",
    });
    mainModule.getAuth().callGoogleSignIn();
  }

  async onSignupWithFacebook(): Promise<void> {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("sign_up", {
      method: "Facebook",
    });
    mainModule.getAuth().callFacebookSignIn();
  }

  html = () => `
    <b-modal narrow="true" modal-title="${Locale.getKey("signup.title")}">
        <div slot="b-modal-top">
          <b-button class="link-back">
            ${ChevronLeftIcon}
            ${Locale.getKey("general.previous")}
          </b-button>
        </div>
        <div slot="b-modal-content" class="form">
          <a href="${MainModule.getInstance().getRouting().getRoute("login")}" class="switch-to-login">
            ${Locale.getKey("signup.alreadyAccount")}
           </a>
          <b-input default-value="${escapeHtml(this.firstName)}"  placeholder="${Locale.getKey("signup.firstNamePlaceholder")}"
          name="firstName" error="${this.errors.firstName}" label="${Locale.getKey("signup.firstName")}"
          ></b-input>
           <b-input default-value="${escapeHtml(this.lastName)}" placeholder="${Locale.getKey("signup.lastNamePlaceholder")}"
           name="lastName" error="${this.errors.lastName}" label="${Locale.getKey("signup.lastName")}"
           ></b-input>
           <b-input default-value="${escapeHtml(this.email)}"  name="email" placeholder="${Locale.getKey("signup.emailPlaceholder")}"
           error="${this.errors.email}"label="${Locale.getKey("signup.email")}"
           ></b-input>
          <b-input
            name="password"
            type="password"
            default-value="${escapeHtml(this.password)}"
            placeholder="${Locale.getKey("signup.passwordPlaceholder")}"
            label="${Locale.getKey("signup.password")}"
            error="${this.errors.password}"
            tooltip="true"
          >
            <div class="password-tooltip-content" slot="tooltip-content">
              <h3>${Locale.getKey("signup.passwordTooltip.title")}</h3>
              <ul>
                <li>${Locale.getKey("signup.passwordTooltip.point1")}</li>
                <li>${Locale.getKey("signup.passwordTooltip.point2")}</li>
                <li>${Locale.getKey("signup.passwordTooltip.point3")}</li>
                <li>${Locale.getKey("signup.passwordTooltip.point4")}</li>
              </ul>
            </div>
          </b-input>
          <b-checkbox name="confirmPolicy" default-value="${this.confirmPolicy}">
            ${Locale.getKey("signup.confirmPolicy")}
          </b-checkbox>
          <b-checkbox default-value="${this.acceptNotifications}" name="confirmNotifications">
            ${Locale.getKey("signup.acceptNotifications")}
          </b-checkbox>
          <b-checkbox default-value="${this.subscribeNewsletter}" name="confirmNewsletter">
            ${Locale.getKey("signup.subscribeNewsletter")}
         </b-checkbox>
          <b-error error="${this.errors.general}"></b-error>
          <b-button name="submit">
            ${Locale.getKey("signup.submit")}
          </b-button>
          <div class="or">
            <div class="or-line"></div>
            <div>
              ${Locale.getKey("general.or")}
            </div>
            <div class="or-line"></div>
          </div>
          <b-button class="google-sign-provider-button">
              ${Locale.getKey("signup.providers.google")}
              ${GoogleIcon}
          </b-button>
          <b-button class="facebook-sign-provider-button">
              ${Locale.getKey("signup.providers.facebook")}
              ${FacebookIcon}
          </b-button>
        </div>
      </b-modal>
  `;

  css = () => `
    :host {

    }


    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #1E007D;
    }

    a>u:hover {
      color: #FFF;
    }

    .password-tooltip-content h3 {
      margin: 0 0 8px;
    }

    .password-tooltip-content ul {
      margin: 0;
      padding-left: 15px;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="submit"]', "click", this.onSignup.bind(this)],
    [
      ".google-sign-provider-button",
      "click",
      this.onSignupWithGoogle.bind(this),
    ],
    [
      ".facebook-sign-provider-button",
      "click",
      this.onSignupWithFacebook.bind(this),
    ],
  ];
}
