import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  AuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  UserCredential,
  signInWithCustomToken,
  signInWithCredential
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  getDocs,
  doc,
  setDoc,
  Firestore,
  updateDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  FirebaseStorage,
} from "firebase/storage";
import axios from "redaxios";
import { replaceTemplate, generateId } from "@/modules/utils";
import { config } from "@/constants/config";
import { Emitter } from "@/modules/emitter";
import { Storage } from "@/modules/storage";

const projectName = config.projectName;

export class AuthModule {
  private _fbApp: FirebaseApp | null = null;
  private _initialized: boolean = false;
  private _firebaseConfig: any = config.firebase;
  private _auth: Auth | null = null;
  private _firestore: Firestore | null = null;
  private _firebaseStorage: FirebaseStorage | null = null;
  private _ee: Emitter | null = null;
  private _storage: Storage = Storage.instance;

  private _isLoggedIn: boolean = false;
  private _user: any = null;
  private _accessToken: string = "";

  constructor(ee: Emitter) {
    this._ee = ee;
  }

  public async onAuthStateChanged(user: any): Promise<void> {
    if (user) {
      this._isLoggedIn = true;
      this._user = user;
      this._accessToken = user.accessToken;

      if (this._firestore) {
        try {
          const { uid } = user;
          let ref = doc(
            this._firestore,
            replaceTemplate(config.auth.userData, { userId: uid }),
          );
          const docSnap = await getDoc(ref);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            this._user = { ...user, profile: userData };
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      this._isLoggedIn = false;
      this._user = null;
    }

    if (!this._initialized) {
      this._initialized = true;
      this._ee?.emit(`${projectName}:auth_initialized`);
    }

    this._ee?.emit(`${projectName}:user_state_changed`, {
      isLoggedIn: this._isLoggedIn,
      user: this._user,
    });
  }

  private async updateUserObject(): Promise<void> {
    if (this.isLoggedIn() && this._firestore && this._user) {
      try {
        let ref = doc(
          this._firestore,
          replaceTemplate(config.auth.userData, { userId: this._user.uid }),
        );
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          this._user = {
            ...this._user,
            profile: userData,
          };
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  public async initialize(ee?: Emitter): Promise<void> {
    if (ee) {
      this._ee = ee;
    }

    if (!this._fbApp) {
      this._fbApp = initializeApp(this._firebaseConfig);
    }

    if (!this._firebaseStorage) {
      this._firebaseStorage = getStorage(this._fbApp);
    }

    if (!this._firestore) {
      this._firestore = getFirestore(this._fbApp);
    }

    if (!this._auth) {
      this._auth = getAuth(this._fbApp);

      onAuthStateChanged(this._auth, this.onAuthStateChanged.bind(this));
    }
    if (this._storage.getItem("signingInWith")) {
      await this.finishProviderSignIn(this._auth);
    }
  }

  private async hasUserProfileDoc(uid: string): Promise<boolean> {
    if (this._firestore) {
      const ref = doc(
        this._firestore,
        replaceTemplate(config.auth.userData, { userId: uid }),
      );
      const docSnap = await getDoc(ref);

      return docSnap.exists();
    } else {
      return false;
    }
  }

  public async login(email: string, password: string): Promise<any> {
    if (this._auth) {
      try {
        let response = await signInWithEmailAndPassword(
          this._auth,
          email,
          password,
        );
        let token = response.user.getIdToken();
        await this.finishSignIn(response?.user, { accessToken: token });
      } catch (error: any) {
        return error;
      }
    }
  }
  
  public async loginWithUsernameAndPassword(username: string, password: string): Promise<any> {
    if (this._auth) {
      try {
        let loginResponse = await axios.post(
          `${config.apis.loginWithUsername}`,
          {
            username,
            password
          }
        );
        
        let response = await signInWithCustomToken(this._auth, loginResponse.data.token);
        
        let token = response.user.getIdToken();
        await this.finishSignIn(response?.user, { accessToken: token });
      } catch (error: any) {
        return error;
      }
    }
  }

  private async signInWithProviderRedirect(
    auth: Auth,
    provider: AuthProvider,
  ): Promise<never> {
    let result = await signInWithRedirect(auth, provider);
    return result;
  }

  public async callGoogleSignIn(): Promise<void> {
    if (this._auth) {
      try {
        var provider = new GoogleAuthProvider();
        this._storage.setItem("signingInWith", "Google");
        await this.signInWithProviderRedirect(this._auth, provider);
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  public async callFacebookSignIn(): Promise<void> {
    if (this._auth) {
      try {
        var provider = new FacebookAuthProvider();
        this._storage.setItem("signingInWith", "Facebook");
        await this.signInWithProviderRedirect(this._auth, provider);
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  private async finishProviderSignIn(auth: Auth): Promise<void> {
    const result: UserCredential | null = await getRedirectResult(auth);
    let signingInWith = this._storage.getItem("signingInWith");
    this._storage.removeItem("signingInWith");
    let credential: any = null;

    if (result === null) {
      this._ee?.emit(`${projectName}:finished_signing_in:failed`);
      return;
    }

    switch (signingInWith) {
      case "Facebook":
        credential = FacebookAuthProvider.credentialFromResult(result);
        break;
      case "Google":
        credential = GoogleAuthProvider.credentialFromResult(result);
        break;
      default:
        break;
    }

    await this.finishSignIn(result.user, credential);
  }
  private async finishSignIn(user: any, credential: any): Promise<void> {
    if (credential && credential.accessToken) {
      this._accessToken = credential.accessToken;

      const hasUserProfileDoc = await this.hasUserProfileDoc(user?.uid);

      this._ee?.emit(`${projectName}:finished_signing_in:success`, {
        hasCompletedProfile: hasUserProfileDoc,
      });
    } else {
      this._ee?.emit(`${projectName}:finished_signing_in:failed`);
    }
  }

  public async completeSignup(options: any = {}) {
    try {
      const result = await axios.post(
        `${config.apis.completeAccount}`,
        {
          ...(options || {}),
        },
        {
          headers: {
            Authorization: `Bearer ${this._accessToken}`,
          },
        },
      );

      return result.data;
    } catch (error) {
      return;
    }
  }

  public async createUser(options: any = {}) {
    try {
      const result = await axios.post(`${config.apis.register}`, {
        ...(options || {}),
      });

      return result.data;
    } catch (error: any) {
      if (error?.data) {
        return {
          success: false,
          error: error.data,
        };
      }
      return {
        success: false,
      };
    }
  }

  public async loadRawProfile(): Promise<any> {
    if (this._firestore && this._user) {
      try {
        const { uid } = this._user;
        let ref: any = doc(
          this._firestore,
          replaceTemplate(config.auth.userData, { userId: uid }),
        );
        let snap: any = await getDoc(ref);
        return snap.data();
      } catch (e) {
        return {};
      }
    }
    return {};
  }

  public async saveRawProfile(value: any = {}): Promise<void> {
    if (this._firestore && this._user) {
      try {
        const { uid } = this._user;
        const previous = await this.loadProfile();
        let ref = doc(
          this._firestore,
          replaceTemplate(config.auth.userData, { userId: uid }),
        );
        let data = {
          ...previous,
          ...value,
        };
        await setDoc(ref, data, { merge: true });
        await this.updateUserObject();
      } catch (error) {
        console.log("error", error);
      }
    } else {
    }
  }

  public async loadProfile(): Promise<any> {
    let profileData = await this.loadRawProfile();
    return profileData;
  }

  public async saveProfile(category: string, value: any): Promise<void> {
    if (this._firestore && this._user) {
      const previous = await this.loadRawProfile();
      let data = {};

      data[category] = {
        ...(previous[category] || {}),
        ...value,
      };

      await this.saveRawProfile(data);
    } else {
    }
  }

  public async deleteAccount(options: any = {}): Promise<void> {
    try {
      const result = await axios.post(
        `${config.apis.deleteAccount}`,
        {
          ...(options || {}),
        },
        {
          headers: {
            Authorization: `Bearer ${this._accessToken}`,
          },
        },
      );

      return result.data;
    } catch (error) {
      return;
    }
  }

  public getFirebaseAuth(): any {
    return this._auth;
  }

  public async logout(): Promise<void> {
    if (this._auth) {
      await signOut(this._auth);
      this._ee?.emit(`${projectName}:user_logged_out`);
    }
  }

  public isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public isInitialized(): boolean {
    return this._initialized;
  }

  public getUserData(): any {
    if (this._isLoggedIn) {
      return this._user;
    } else return null;
  }
  public getUserAuth(): any {
    if (this._isLoggedIn) {
      return this._auth?.currentUser || null;
    } else return null;
  }

  public async getFileLink(filePath: string): Promise<string> {
    if (!this._initialized) throw new Error("Firebase not initialized");

    if (!this._firebaseStorage)
      throw new Error("Firebase storage not initialized");

    const storageRef = ref(this._firebaseStorage, filePath);

    return await getDownloadURL(storageRef);
  }

  public async uploadFile(pathPrefix: string, file: File): Promise<string> {
    if (!this._initialized) throw new Error("Firebase not initialized");

    if (!this._firebaseStorage)
      throw new Error("Firebase storage not initialized");

    let filePath = `${pathPrefix}/${generateId()}_${file.name.replaceAll(" ", "_")}`;

    const storageRef = ref(this._firebaseStorage, filePath);
    const snapshot = await uploadBytes(storageRef, file);

    if (!snapshot) throw new Error("Failed to upload file");

    return filePath;
  }
}
