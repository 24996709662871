import Minze, { MinzeElement, Reactive, EventListeners } from "minze";
import { MainModule } from "@/modules/main";
import redaxios from "redaxios";
import { config } from "@/constants/config";
import { Locale } from "@/modules/locale";
import { EMAIL_REGEX, escapeHtml, unescapeHtml } from "@/modules/utils";
import { Routing } from "@/modules/routing";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";

Locale.getInstance().registerKeySet("fr", "forgotPassword", {
  title: "Mot de passe oublié?",
  description:
    "Entrez votre courriel pour recevoir les instructions de réinitialisation.",
  email: "Courriel",
  emailPlaceholder: "Entrez votre adresse courriel",
  send: "Envoyer un lien de réinitialisation",
  errors: {
    email: "Veuillez entrer une adresse courriel valide.",
    misc: "Une erreur est survenue. Veuillez réessayer plus tard.",
  },
});

Locale.getInstance().registerKeySet("en", "forgotPassword", {
  title: "Forgot password?",
  description: "Enter e-mail to receive instructions on how to reset password.",
  email: "E-mail",
  emailPlaceholder: "Enter your email address",
  send: "Send link to reset password",
  errors: {
    email: "Please enter a valid email address.",
    misc: "An error occurred. Please try again later.",
  },
});

export interface BForgotPassword {}

export class BForgotPassword extends MinzeElement {
  private email: string = "";
  errors: any = {
    email: false,
  };

  goBack = () => {
    const mainModule = MainModule.getInstance();
    const routing = mainModule.getRouting();

    routing.goToLastPage();
  };

  onReady(): void | Promise<void> {
    const mainModule = MainModule.getInstance();

    const userData = mainModule.getAuth().getUserData();

    if (userData && userData.profile) {
      mainModule.getRouting().goToLastPage();
    }
    console.log("forgot password ready");
  }

  async save(): Promise<void> {
    const mainModule = MainModule.getInstance();
    const emailField = this.select('[name="email"]') as HTMLInputElement;

    const email = unescapeHtml(emailField?.value);
    this.email = email;

    for (const key in this.errors) {
      this.errors[key] = false;
    }

    if (!email || EMAIL_REGEX.test(email) === false) {
      this.errors.email = Locale.getKey("forgotPassword.errors.email");
    }

    let hasError = false;
    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      this.rerender();
      return;
    }

    const response = await redaxios.post(
      `${config.backendApi}/api/user/forgot-password`,
      {
        email,
      },
    );

    if (response.status === 200) {
      const language = Locale.language;
      mainModule
        .getRouting()
        .goTo(
          `${Routing.ROUTES[language].login}?recover-password-email-sent=true`,
        );
    } else {
      this.errors.email = Locale.getKey("forgotPassword.errors.misc");
      this.rerender();
    }
  }

  html = () => `
    <b-modal narrow="true" modal-title="${Locale.getKey("forgotPassword.title")}">
      <div slot="b-modal-top">
        <b-button class="link-back">
          ${ChevronLeftIcon}
          ${Locale.getKey("general.previous")}
        </b-button>
      </div>
      <div slot="b-modal-content" class="form">
        <b-text font-preset="small">
          ${Locale.getKey("forgotPassword.description")}
        </b-text>
        <b-input id="email" name="email" clear-enabled="true" placeholder="${Locale.getKey("forgotPassword.emailPlaceholder")}" default-value="${escapeHtml(this.email)}" label="${Locale.getKey("forgotPassword.email")}" error="${this.errors.email}">
        </b-input>
        <b-button
          name="save"
        >
          ${Locale.getKey("forgotPassword.send")}
        </b-button>
      </div>
    </b-modal>
  `;

  css = () => `
    :host {

    }

    div[slot="b-modal-top"] {
      display: flex;
      align-items: center;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="save"]', "click", this.save.bind(this)],
    [".link-back", "click", this.goBack.bind(this)],
  ];
}
