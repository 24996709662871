
import { MinzeElement, Reactive, EventListeners } from "minze";
import { writeCss } from "@/modules/utils";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import { Style } from "@/constants";


Locale.getInstance().registerKeySet("fr", "sscforgotpasswordsent", {
  welcome: "Bienvenue chez",
  company: "Succès Scolaire",
  resetPasswordSent: "Un lien de réinitialisation vous a été envoyé par courriel!",
});

Locale.getInstance().registerKeySet("en", "sscforgotpasswordsent", {
  welcome: "Welcome to",
  company: "Succès Scolaire",
  resetPasswordSent: "A reset link has been sent to you by email!",
});

export interface SscForgotPasswordSent {
  bgImageUrl?: string;
}

export class SscForgotPasswordSent extends MinzeElement {

  reactive: Reactive = [
    [
      "bgImageUrl",
      "https://storage.googleapis.com/successcolaire.appspot.com/public/account_screen_background.png",
    ],
  ];

  private errors: any = {
    email: false,
    general: false,
  };

  async onReady(): Promise<void> {
  }

  onDestroy() {}

  html = () => {
    return `
      <ssc-modal
        background-image-url="${this.bgImageUrl}"
        narrow="true"
        modal-title="">
        <div slot="b-modal-overlay" stlye="z-index: 1">
          <ssc-logo style="z-index:1"></ssc-logo>
        </div>
        <div slot="b-modal-content" class="form">
          <div class="left">
          </div>
          <div class="right">
            <div class="title">
              <h3>${Locale.getKey("sscforgotpasswordsent.welcome")}</h3>
              <h1>${Locale.getKey("sscforgotpasswordsent.company")}</h1>
            </div>
            <div class="title-spacer"></div>
            <div><p>${Locale.getKey("sscforgotpasswordsent.resetPasswordSent")}</p></div>
          </div>
        </div>
        <ssc-footer slot="b-modal-footer">
        </ssc-footer>
      </ssc-modal>
    `;
  };

  css = () => `
    :host {

    }
    .switch-to-signup {
      cursor: pointer;
    }


    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #000;
    }

    a>u:hover {
      color: #dd1d1d;
    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      padding: 37px 94px 46px 68px;
      gap: 20px;
    }


    .left {
      background: #FECA36;
      background-image: url('https://storage.googleapis.com/successcolaire.appspot.com/public/repetitive_dot.png');
      background-size: 22px;
      flex: 1;
      box-sizing: border-box;
      border: 30px solid #FECA36;
      max-width: 200px;
    }

    h3 {
      color: #0D465B;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 200% */
    }

    h1 {
      color: #0D465B;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }

    .title-spacer {
      height: 40px;
    }
    ssc-logo {
      margin: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }


    ${writeCss(Style.link.template, "a[href], a[href]:visited, a[href]:link")}

    .loading {
      pointer-events: none;
      opacity: 0.5;
    }
  `;

  eventListeners: EventListeners = [
  ];
}
