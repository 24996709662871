import Minze, {
  Attrs,
  EventListeners,
  MinzeElement,
  Reactive,
  Watch,
} from "minze";
import * as moment from "moment";
import { Font } from "@/constants";
import { Callback } from "@/modules/callback";
import {
  MaskOptions,
  applyMask,
  parseInlineStyle,
  generateId,
  unescapeHtml,
  escapeHtml,
} from "../../modules/utils";
import ClearIcon from "iconoir/icons/solid/xmark-circle.svg?raw";
import HidePasswordIcon from "iconoir/icons/regular/eye.svg?raw";
import ShowPasswordIcon from "iconoir/icons/regular/eye-closed.svg?raw";
import TooltipIcon from "iconoir/icons/regular/help-circle.svg?raw";

interface InputFieldChangeEvent {
  fieldId: string;
  value: string;
  onChange: string;
}

export interface BInput {
  internalValue?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  height?: string;
  type?: string;
  label?: string;
  padding?: string;
  error?: string | boolean;
  readonly?: boolean;
  tooltip?: boolean;
  onChange?: string;
  clearEnabled?: boolean;
  fontSize?: string;
  marginTop?: string;
  innerStyle?: string;
  mask?: string;
  showPassword?: boolean;
  fontSizeValue?: string;
  heightValue?: string;
  paddingValue?: string;
  tooltipOpened?: boolean;
  disabled?: boolean;
  border?: string;
  borderColor?: string;
  background?: string;
  placeholderColor?: string;
}

export class BInput extends MinzeElement {
  private refId: string = generateId();
  private isFocused: boolean = false;
  private startSelection: number | null = null;
  private endSelection: number | null = null;
  reactive: Reactive = [
    ["value", "", true],
    ["internal-value", "", false],
    ["tooltipOpened", false],
  ];

  attrs: Attrs = [
    ["disabled", false],
    ["placeholder", ""],
    ["height", "48px"],
    "type",
    "mask",
    ["error", false],
    "input-name",
    ["default-value", ""],
    ["date-format", "YYYY-MM-DD"],
    "label",
    ["padding", "12px 16px"],
    ["fontSize", "14px"],
    ["marginTop", "4px"],
    ["background", "white"],
    ["clear-enabled", false],
    "placeholder-color",
    "border",
    ["inner-style", ""],
    ["readonly", false],
    ["tooltip", false],
    ["on-change", null],
  ];

  static observedAttributes = [
    "inner-style",
    "placeholder",
    "height",
    "type",
    "input-name",
    "label",
    "default-value",
    "padding",
    "error",
    "readonly",
    "tooltip",
    "on-change",
  ];

  fieldType: string = "text";

  onReady() {
    if (this.defaultValue && typeof this.defaultValue === "string") {
      this.internalValue = unescapeHtml(this.defaultValue);
      this.value = escapeHtml(this.internalValue);
    }

    this.fieldType = this.type as string;
  }

  beforeAttributeChange = (
    name: string,
    oldValue: string,
    newValue: string,
  ) => {
    if (name === "type") {
      this.fieldType = newValue;
    }

    if (
      (name === "default-value" || name === "value") &&
      oldValue !== newValue
    ) {
      let valueToSet = newValue;
      let oldSetValue = oldValue;

      if (newValue === null) {
        valueToSet = "";
      }

      if (oldValue === null) {
        oldSetValue = "";
      }

      if (oldValue === null) {
        oldValue = "";
      }

      if (oldSetValue !== valueToSet) {
        this.internalValue = valueToSet;
        this.value = escapeHtml(valueToSet);
        //only value can update input directly if changed from outside
        //or else you risk forever loop
        if (name === "value") {
          const currentElement = this.select(`[data-main-input="true"]`);
          if (currentElement as any) {
            (currentElement as any).value = valueToSet;
          }
        }
      }
    }

    if (
      (name === "default-value" || name === "value") &&
      oldValue !== newValue
    ) {
    }

    this.paddingValue = this.padding ? this.padding : "12px 16px";
    this.fontSizeValue = this.fontSize ? this.fontSize : "14px";
    this.heightValue = this.height ? this.height : "48px";
  };

  closeTooltip = () => (this.tooltipOpened = false);
  openTooltip = () => (this.tooltipOpened = true);

  handleInput = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    let value = target.value;
    let pressedKey = (event as KeyboardEvent)?.key as string;
    if (this.mask && pressedKey !== "Backspace") {
      target.value = value;
    }
    this.internalValue = value;
    this.value = escapeHtml(value);
    Callback.call(this.onChange as string, {
      value: this.internalValue,
      target: this,
    });
    this.dispatch("update", { value: this.internalValue, target: this });
  };

  async onInput(event: KeyboardEvent): Promise<void> {
    const inputElement = event.target as HTMLInputElement;
    const selectionStart = inputElement.selectionStart || 0;
    const selectionEnd = inputElement.selectionEnd || 0;
    this.startSelection = selectionStart;
    this.endSelection = selectionEnd;
    let maskedValue = inputElement.value;
    if (this.mask) {
      maskedValue = applyMask(inputElement.value, {
        mask: this.mask as string,
        patternsMap: {
          A: /[a-zA-Z]/,
          "#": /\d/,
          "*": /./,
        } as MaskOptions["patternsMap"],
      });
    }

    // Update the input field with the masked value
    inputElement.value = maskedValue;

    // Update the stored value
    this.internalValue = maskedValue;
    this.value = escapeHtml(maskedValue);
    Callback.call(this.onChange as string, {
      value: this.internalValue,
      target: this,
    });
    this.dispatch("update", { value: this.internalValue, target: this });
  }

  beforeRender() {
    if (!this.shadowRoot) return;

    if (this.shadowRoot.activeElement) {
      let inputElement = this.shadowRoot.activeElement as HTMLInputElement;

      if (inputElement !== this.select(`[data-main-input="true"]`)) {
        this.isFocused = false;
      } else {
        this.isFocused = true;
        this.startSelection = inputElement.selectionStart;
        this.endSelection = inputElement.selectionEnd;
      }
    }
  }

  afterRender() {
    if (!this.shadowRoot) return;

    if (this.isFocused) {
      let inputElement = this.select(
        `[data-main-input="true"]`,
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.selectionStart = this.startSelection;
        inputElement.selectionEnd = this.endSelection;
      }
    }
  }

  clearInput = () => {
    if (this.internalValue === "") return;

    this.internalValue = "";
    this.value = "";
    this.rerender();
    Callback.call(this.onChange as string, this.internalValue);
    this.dispatch("update", { value: this.internalValue, target: this });
  };

  onPasswordVisibilityChange = () => {
    this.showPassword = !this.showPassword;
    this.rerender();
  };

  html = () => {
    let fieldType = this.fieldType;
    let fieldValue = escapeHtml(this.internalValue);

    if (fieldType === "password" && this.showPassword) {
      fieldType = "text";
    }

    let showError = typeof this.error === "string" && this.error.length > 0;

    return `
      <div class="container">
        ${
          this.label
            ? `
          <div class="label-container">
            <label for="${this.refId}">${this.label}</label>
            ${this.tooltipOpened ? `<div class="close-overlay"></div>` : ""}
            ${
              this.tooltip
                ? `
              <div class="tooltip-container">
                <span name="tooltipButton">${TooltipIcon}</span>
                <dialog class="tooltip" ${this.tooltipOpened ? "open" : ""}>
                  <slot name="tooltip-content"></slot>
                </dialog>
              </div>
            `
                : ``
            }
          </div>
        `
            : ""
        }
        <div class="input-container">
          <input
            ${this.disabled ? `disabled` : ""}
            placeholder="${this.placeholder || ""}"
            ${fieldType ? `type="${fieldType}"` : ""}
            data-main-input="true"
            name="${this.name}"
            id="${this.refId}"
            ${fieldValue ? `value="${fieldValue}"` : ""}
            mask="${this.mask}"
            class="input ${this.error ? "error" : ""}"
            ${this.readonly ? `readonly` : ""}
          />
        ${
          this.clearEnabled
            ? `
          ${
            this.value
              ? `
            <button name="clearInput" class="clear-input-button">
              ${ClearIcon}
            </button>
          `
              : ""
          }
        `
            : ``
        }
        ${
          this.type == "password"
            ? `
          <button name="showPassword" class="show-password-button">
            ${this.showPassword ? ShowPasswordIcon : HidePasswordIcon}
          </button>
        `
            : ``
        }
        </div>
      </div>
      ${showError ? `<b-error error="${this.error}"></b-error>` : ""}
      `;
  };

  css = () => {
    return `
    :host {
      font-family: ${Font.family};
      width: 100%;
      position: relative;
      box-sizing: border-box;
      display: block;
    }

    .container {
      height: auto;
    }

    .input:focus-visible {
      outline: unset;
    }
    .input-container {
      position: relative;
      ${this.marginTop ? `margin-top: ${this.marginTop};` : "4px;"}
    }

    .tooltip-container {
      position: relative;
      margin-left: 4px;
    }

    .label-container {
      display: flex;
      align-items: center;
    }

    .input {
      font-family: ${Font.family};
      width: 100%;
      border: ${this.border ? this.border : "unset"};
      ${this.heightValue ? `height: ${this.heightValue};` : ""}
      ${this.fontSizeValue ? `font-size: ${this.fontSizeValue};` : ""}
      ${this.paddingValue ? `padding: ${this.paddingValue};` : ""}
      background: ${this.background ? this.background : "#fff"};
      color: #000;
      ${this.type === "password" ? `border-radius: 99px;` : ""}
    }


    ${
      this.placeholderColor
        ? `
      ::placeholder {
        color: ${this.placeholderColor};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${this.placeholderColor};
      }

      ::-ms-input-placeholder {
        color: ${this.placeholderColor};
      }
    `
        : ``
    }

    label {
      font-family: ${Font.family};
      color:  #000;
      font-size: 14px;
      line-height: 17px;
      font-weight: ${Font.weight.bold};
      margin-bottom: 4px;
    }

    .show-password-button {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
    }

    .show-password-button b-close-icon {
    }

    .clear-input-button {
      cursor: pointer;
      position: absolute;
      right: 5px;
      width: 28px;
      height: 28px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
    }

    .clear-input-button b-close-icon {
    }

    .error {
      background: #FFEBD7;
      border-bottom: 3px solid #FF4600;
    }

    b-show-password-icon, b-hide-password-icon {
      width: 20px;
      height: 20px;
    }

    b-help-icon {
      cursor: pointer;
    }

    [name="tooltipButton"] {
      cursor: pointer;
    }

    .tooltip {
      z-index: 1000;

      border: 1px solid #d11d1d;
      box-shadow: 0px 4px 16px rgba(0,0,0, 0.15);
      border-radius: 8px;
      padding: 16px;
      font-family: ${Font.family};
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      line-height: 150%;
      color: #000;
      background: #fff;
      max-width: 90vw;
      width: 340px;
    }

    .close-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    ${parseInlineStyle(this.innerStyle as string)}
  `;
  };
  eventListeners: EventListeners = [
    ["input", "input", this.onInput.bind(this)],
    ["input", "blur", this.handleInput.bind(this)],
    ["input", "change", this.handleInput.bind(this)],
    [".clear-input-button", "click", this.clearInput.bind(this)],
    [
      '[name="showPassword"]',
      "click",
      this.onPasswordVisibilityChange.bind(this),
    ],
    ['[name="tooltipButton"]', "click", this.openTooltip.bind(this)],
    [".close-overlay", "click", this.closeTooltip.bind(this)],
  ];
}
