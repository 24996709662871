export default `<svg width="149" height="52" viewBox="0 0 149 52" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M84.1192 32.135C82.6444 30.2956 79.7917 28.333 75.559 26.2456C71.3263 24.1589 68.5707 22.388 67.2913 20.935C66.372 19.8736 65.9127 18.7273 65.9127 17.4968C65.9127 16.1002 66.5028 14.8621 67.6822 13.7865C67.8235 13.658 67.9716 13.5369 68.1249 13.4234C69.2494 12.5898 70.6896 12.1711 72.4493 12.1711C72.9206 12.1711 73.3739 12.2027 73.8129 12.2621C75.5222 12.4944 76.9902 13.1754 78.2155 14.3089C79.7557 15.7341 80.6238 17.6764 80.8185 20.1344L83.8192 20.3847C84.1808 16.2678 84.5551 13.0822 84.9452 10.8286C82.9029 10.004 80.9395 9.41096 79.0543 9.04715C77.5705 8.76151 76.1356 8.61794 74.7502 8.61794C74.2721 8.61794 73.8061 8.63448 73.3483 8.66079L72.2216 9.39368L72.1133 9.46434L71.984 9.47335L67.9212 9.77478C66.4156 10.3588 65.0761 11.1631 63.905 12.1899C63.4743 12.568 63.0811 12.9544 62.7188 13.3483C60.7968 15.4357 59.8316 17.7433 59.8316 20.275C59.8316 22.3925 60.5277 24.2852 61.9213 25.9562C63.2856 27.6279 66.03 29.4944 70.1544 31.5555C74.2767 33.6181 76.9369 35.3447 78.135 36.7398C79.3054 38.1342 79.8917 39.6548 79.8917 41.3002C79.8917 43.002 79.2904 44.4032 78.0907 45.5044C76.891 46.6063 75.2005 47.1558 73.0236 47.1558C70.5949 47.1558 68.5504 46.4492 66.8899 45.0323C65.2294 43.6177 63.9937 41.2717 63.1849 37.9959H60.3413C60.3413 41.2416 60.118 44.8204 59.6738 48.7321C63.8178 50.261 67.6002 51.0262 71.0204 51.0262C75.7477 51.0262 79.4745 49.786 82.2001 47.3077C84.9242 44.8271 86.2885 41.8881 86.2885 38.4867C86.2885 36.1189 85.5654 34.0007 84.1192 32.135Z" fill="#FECA38"/>
  <path d="M72.2215 9.39363L72.1125 9.46429L71.984 9.47406L61.9017 10.2205L62.7278 13.382C69.4358 13.8924 74.9614 12.8995 79.0558 9.04561L78.0568 5.59915L72.2215 9.39363Z" fill="black"/>
  <path d="M57.0369 10.0808L61.7717 9.72446L71.9465 8.97128L77.9103 5.09336L84.2477 0.971897L84.062 -2.47955e-05L66.9666 1.76116L53.6581 9.33584L53.9738 10.3025L56.5325 10.1176V12.0472C56.0078 12.1749 55.6267 12.6575 55.6478 13.2183C55.6643 13.6881 55.9597 14.0804 56.3686 14.2481C56.2221 15.5349 55.4313 20.051 55.5794 20.3472C55.632 20.4532 55.7177 20.5254 55.8154 20.5456C55.838 20.5502 55.8613 20.5524 55.8846 20.5517C55.9552 20.5487 56.0259 20.5201 56.0898 20.4667C56.1574 20.4103 56.2348 20.024 56.304 19.4903C56.2942 20.1089 56.307 20.5374 56.3536 20.6201C56.4122 20.723 56.5017 20.7899 56.6002 20.8057C56.6182 20.808 56.6362 20.8095 56.6543 20.8087C56.7302 20.8057 56.8054 20.7727 56.87 20.7125C56.9985 20.593 57.0587 20.4291 57.0436 19.237C57.1158 20.4269 57.188 20.5862 57.3255 20.696C57.3939 20.7509 57.4714 20.7787 57.5473 20.7757C57.5653 20.7749 57.5834 20.7727 57.6006 20.7689C57.6976 20.7463 57.7825 20.6727 57.8329 20.5659C57.875 20.478 57.854 20.033 57.7961 19.3956C57.9066 19.9781 58.0178 20.3893 58.0983 20.4479C58.1659 20.496 58.2388 20.5193 58.3095 20.5171C58.3328 20.5156 58.3553 20.5126 58.3779 20.5058C58.4733 20.478 58.5793 20.4073 58.5989 20.2908C58.659 19.9315 57.5894 15.2959 57.3466 14.2007C57.7292 13.9993 57.9863 13.5941 57.969 13.1333C57.9487 12.5854 57.5518 12.1434 57.0369 12.0396V10.0808Z" fill="black"/>
  <path d="M140.334 21.8851H86.4133V21.7588H140.334V21.8851Z" fill="#F6A200"/>
  <path d="M140.313 39.1572H88.1484V38.6529H140.313V39.1572Z" fill="#F6A200"/>
  <path d="M58.4203 39.1573H0.000488281V38.6529H58.4203V39.1573Z" fill="#F6A200"/>
  <path d="M55.1215 21.8982H0L0.000751267 21.7719H55.1215V21.8982Z" fill="#F6A200"/>
  <path d="M1.00315 34.3507C0.840036 34.5589 0.758103 34.8205 0.758103 35.1362H0.227417V32.7023H0.84154C0.997137 33.2811 1.31961 33.7501 1.81121 34.1087C2.30205 34.468 2.89588 34.6476 3.59194 34.6476C4.46313 34.6476 5.04569 34.4124 5.34035 33.9441C5.43581 33.7847 5.48316 33.6073 5.48316 33.4089C5.48316 32.9872 5.32982 32.6241 5.02389 32.3219C4.7172 32.0198 4.19328 31.7364 3.45212 31.471C2.71022 31.2049 2.14946 30.9614 1.76986 30.7404C1.38951 30.5202 1.09035 30.2879 0.871607 30.0428C0.485995 29.6136 0.292813 29.0491 0.292813 28.3516C0.292813 27.654 0.60025 27.0887 1.21512 26.6543C1.82925 26.2205 2.57567 26.0033 3.45438 26.0033C3.95199 26.0033 4.37444 26.0604 4.72246 26.1754C5.07049 26.2897 5.33583 26.4243 5.51925 26.5783C5.61471 26.4062 5.66582 26.216 5.67409 26.0093H6.22883V28.2283H5.65605C5.59667 27.6758 5.35087 27.2496 4.9194 26.9489C4.48794 26.649 3.98882 26.4979 3.42206 26.4979C2.85529 26.4979 2.39301 26.6122 2.03521 26.8384C1.67666 27.0654 1.49776 27.3744 1.49776 27.766C1.49776 28.1569 1.66538 28.4996 1.99913 28.7943C2.33287 29.089 3.0154 29.4573 4.0452 29.8993C5.01186 30.2826 5.68612 30.6712 6.06797 31.0644C6.44983 31.4575 6.64075 31.9431 6.64075 32.5211C6.64075 33.3344 6.34685 33.9749 5.76053 34.4417C5.17347 34.9092 4.46389 35.1422 3.63102 35.1422C2.79741 35.1422 2.07279 34.9791 1.45641 34.6536C1.27376 34.5567 1.12267 34.4552 1.00315 34.3507Z" fill="black"/>
  <path d="M9.63403 26.4745H8.81696V26.0092H12.1101V26.4745H11.2389V31.9279C11.2389 33.3516 11.6448 34.195 12.4558 34.4581C12.7264 34.5445 12.9895 34.5874 13.2466 34.5874C13.5029 34.5874 13.7254 34.5761 13.9141 34.5521C14.1035 34.5288 14.2914 34.4837 14.4786 34.4168C14.8958 34.2597 15.2386 33.9778 15.5069 33.5696C15.776 33.1622 15.9098 32.5796 15.9098 31.8227V26.4745H14.9792V26.0092H17.3658V26.4745H16.4946V31.9099C16.4946 32.9968 16.2082 33.8079 15.6354 34.3416C15.0627 34.8753 14.2854 35.1421 13.303 35.1421C11.4538 35.1421 10.2925 34.4513 9.81895 33.0697C9.69191 32.7007 9.62802 32.2549 9.62802 31.7325L9.63403 26.4745Z" fill="black"/>
  <path d="M26.7426 26.7694C26.9019 26.5492 26.9854 26.2951 26.9929 26.0095H27.4822V28.5088H26.9749C26.8598 27.8842 26.5441 27.3926 26.0292 27.0348C25.5143 26.677 24.9543 26.4981 24.35 26.4981C22.3377 26.4981 21.3395 27.8654 21.356 30.6008C21.3673 31.6877 21.5906 32.5912 22.0235 33.3106C22.5249 34.1419 23.2525 34.5576 24.2072 34.5576C24.9348 34.5576 25.5504 34.4336 26.0533 34.1855C26.5569 33.9367 26.9516 33.6 27.2372 33.1768L27.5476 33.4541C27.3965 33.8082 27.0823 34.1472 26.6058 34.4704C25.9496 34.9184 25.1257 35.1424 24.1358 35.1424C22.7279 35.1424 21.6056 34.7147 20.7705 33.8593C19.9354 33.0031 19.5182 31.9139 19.5182 30.5902C19.5182 29.2673 19.9346 28.1721 20.7682 27.3046C21.6011 26.4365 22.7594 26.0027 24.2432 26.0027C25.046 26.0027 25.7459 26.1666 26.3427 26.4943C26.5103 26.586 26.6434 26.6777 26.7426 26.7694Z" fill="black"/>
  <path d="M37.1148 26.7694C37.2742 26.5492 37.3576 26.2951 37.3651 26.0095H37.8545V28.5088H37.3471C37.2321 27.8842 36.9164 27.3926 36.4015 27.0348C35.8866 26.677 35.3266 26.4981 34.7222 26.4981C32.71 26.4981 31.7118 27.8654 31.7283 30.6008C31.7396 31.6877 31.9628 32.5912 32.3958 33.3106C32.8972 34.1419 33.6248 34.5576 34.5794 34.5576C35.307 34.5576 35.9227 34.4336 36.4255 34.1855C36.9292 33.9367 37.3238 33.6 37.6094 33.1768L37.9199 33.4541C37.7688 33.8082 37.4546 34.1472 36.9773 34.4704C36.3211 34.9184 35.498 35.1424 34.508 35.1424C33.1001 35.1424 31.9779 34.7147 31.1435 33.8593C30.3084 33.0031 29.8904 31.9139 29.8904 30.5902C29.8904 29.2673 30.3069 28.1721 31.1405 27.3046C31.9733 26.4365 33.1317 26.0027 34.6155 26.0027C35.4183 26.0027 36.1181 26.1666 36.7149 26.4943C36.8826 26.586 37.0156 26.6777 37.1148 26.7694Z" fill="black"/>
  <path d="M42.9542 24.8413C42.6814 24.8353 42.4837 24.7255 42.3604 24.512C42.2371 24.2986 42.2093 24.1039 42.277 23.9287C42.3446 23.7528 42.4506 23.6303 42.5957 23.5597C42.74 23.489 42.864 23.4597 42.9678 23.471C43.0715 23.4815 43.1857 23.5153 43.312 23.5717L45.4911 24.5564L45.4453 24.8277L42.9542 24.8413ZM40.1144 34.665H41.308L41.3133 26.4747H40.1144V26.0094H47.8281V28.6718H47.3621C47.3425 28.3231 47.3147 28.0134 47.2786 27.7435C47.2433 27.4722 47.1674 27.2429 47.0524 27.0535C46.8216 26.6679 46.3526 26.4747 45.6445 26.4747H42.9181V30.2211H44.4095C44.7477 30.2211 45.0071 30.1692 45.1882 30.0662C45.3694 29.9632 45.4791 29.7821 45.519 29.5235C45.5272 29.4483 45.534 29.3686 45.54 29.2837C45.546 29.1995 45.5535 29.1161 45.5611 29.0334H46.0617V32.1123H45.5611C45.5535 32.0161 45.546 31.9048 45.54 31.7793C45.534 31.6538 45.5272 31.5485 45.519 31.4651C45.4791 31.1757 45.3671 30.9742 45.1822 30.8585C44.9973 30.7442 44.7395 30.6863 44.4095 30.6863H42.9181V34.665H45.4716C46.5179 34.665 47.1516 34.1967 47.3749 33.2586C47.438 32.988 47.4801 32.7114 47.4996 32.425H47.9649V35.1363H40.1144V34.665Z" fill="black"/>
  <path d="M51.9012 34.3507C51.738 34.5589 51.6561 34.8205 51.6561 35.1362H51.1254V32.7023H51.7395C51.8951 33.2811 52.2184 33.7501 52.7092 34.1087C53.2008 34.468 53.7939 34.6476 54.4899 34.6476C55.3611 34.6476 55.9437 34.4124 56.2384 33.9441C56.3338 33.7847 56.3812 33.6073 56.3812 33.4089C56.3812 32.9872 56.2278 32.6241 55.9219 32.3219C55.6152 32.0198 55.0913 31.7364 54.3501 31.471C53.6082 31.2049 53.0475 30.9614 52.6679 30.7404C52.2875 30.5202 51.9884 30.2879 51.7696 30.0428C51.384 29.6136 51.1916 29.0491 51.1916 28.3516C51.1916 27.654 51.4983 27.0887 52.1131 26.6543C52.7273 26.2205 53.4737 26.0033 54.3524 26.0033C54.85 26.0033 55.2724 26.0604 55.6205 26.1754C55.9685 26.2897 56.2338 26.4243 56.4173 26.5783C56.5127 26.4062 56.5638 26.216 56.5721 26.0093H57.1268V28.2283H56.5541C56.4947 27.6758 56.2489 27.2496 55.8174 26.9489C55.386 26.649 54.8868 26.4979 54.3201 26.4979C53.7533 26.4979 53.291 26.6122 52.9332 26.8384C52.5747 27.0654 52.3958 27.3744 52.3958 27.766C52.3958 28.1569 52.5634 28.4996 52.8971 28.7943C53.2316 29.089 53.9134 29.4573 54.944 29.8993C55.9099 30.2826 56.5841 30.6712 56.966 31.0644C57.3478 31.4575 57.5388 31.9431 57.5388 32.5211C57.5388 33.3344 57.2449 33.9749 56.6585 34.4417C56.0715 34.9092 55.3619 35.1422 54.529 35.1422C53.6954 35.1422 52.9708 34.9791 52.3544 34.6536C52.1718 34.5567 52.0207 34.4552 51.9012 34.3507Z" fill="black"/>
  <path d="M65.4626 34.3507C65.2995 34.5589 65.2176 34.8205 65.2176 35.1362H64.6869V32.7023H65.301C65.4566 33.2811 65.7791 33.7501 66.2707 34.1087C66.7615 34.468 67.3554 34.6476 68.0514 34.6476C68.9226 34.6476 69.5052 34.4124 69.7998 33.9441C69.8953 33.7847 69.9426 33.6073 69.9426 33.4089C69.9426 32.9872 69.7893 32.6241 69.4826 32.3219C69.1767 32.0198 68.6528 31.7364 67.9116 31.471C67.1697 31.2049 66.6089 30.9614 66.2293 30.7404C65.849 30.5202 65.5498 30.2879 65.3311 30.0428C64.9455 29.6136 64.7523 29.0491 64.7523 28.3516C64.7523 27.654 65.0597 27.0887 65.6746 26.6543C66.2887 26.2205 67.0351 26.0033 67.9139 26.0033C68.4115 26.0033 68.8339 26.0604 69.1819 26.1754C69.53 26.2897 69.7953 26.4243 69.9787 26.5783C70.0742 26.4062 70.1253 26.216 70.1336 26.0093H70.6883V28.2283H70.1155C70.0561 27.6758 69.8103 27.2496 69.3789 26.9489C68.9474 26.649 68.4483 26.4979 67.8815 26.4979C67.3148 26.4979 66.8525 26.6122 66.4947 26.8384C66.1361 27.0654 65.9572 27.3744 65.9572 27.766C65.9572 28.1569 66.1249 28.4996 66.4586 28.7943C66.7931 29.089 67.4749 29.4573 68.5047 29.8993C69.4713 30.2826 70.1456 30.6712 70.5267 31.0644C70.9085 31.4575 71.1002 31.9431 71.1002 32.5211C71.1002 33.3344 70.8063 33.9749 70.22 34.4417C69.6329 34.9092 68.9234 35.1422 68.0905 35.1422C67.2569 35.1422 66.5323 34.9791 65.9159 34.6536C65.7332 34.5567 65.5821 34.4552 65.4626 34.3507Z" fill="black"/>
  <path d="M80.7983 26.7694C80.9576 26.5492 81.041 26.2951 81.0493 26.0095H81.5379V28.5088H81.0313C80.9155 27.8842 80.6006 27.3926 80.0857 27.0348C79.5708 26.677 79.0108 26.4981 78.4057 26.4981C76.3934 26.4981 75.3959 27.8654 75.4117 30.6008C75.423 31.6877 75.6462 32.5912 76.08 33.3106C76.5806 34.1419 77.309 34.5576 78.2628 34.5576C78.9905 34.5576 79.6061 34.4336 80.1097 34.1855C80.6126 33.9367 81.0072 33.6 81.2929 33.1768L81.6033 33.4541C81.4522 33.8082 81.138 34.1472 80.6614 34.4704C80.0052 34.9184 79.1814 35.1424 78.1914 35.1424C76.7835 35.1424 75.662 34.7147 74.8269 33.8593C73.9918 33.0031 73.5739 31.9139 73.5739 30.5902C73.5739 29.2673 73.9903 28.1721 74.8239 27.3046C75.6575 26.4365 76.8151 26.0027 78.2989 26.0027C79.1017 26.0027 79.8015 26.1666 80.3984 26.4943C80.566 26.586 80.699 26.6777 80.7983 26.7694Z" fill="black"/>
  <path d="M90.6286 33.295C90.7789 33.0281 90.9262 32.6703 91.0668 32.2208C91.2081 31.7721 91.2788 31.3015 91.2788 30.8077C91.2788 30.3145 91.2555 29.9026 91.2096 29.5711C91.1645 29.2404 91.0939 28.9262 90.9984 28.6285C90.7879 27.9866 90.4722 27.4702 90.0528 27.0816C89.6334 26.693 89.1072 26.4983 88.475 26.4983C87.4881 26.4983 86.7544 26.9282 86.2733 27.7866C85.8637 28.5255 85.666 29.4441 85.6803 30.5408C85.6938 31.6375 85.8998 32.5561 86.2974 33.295C86.7905 34.1962 87.5084 34.6472 88.451 34.6472C89.3936 34.6472 90.1189 34.1962 90.6286 33.295ZM84.4656 32.8169C84.2987 32.4771 84.1732 32.1163 84.0875 31.7337C84.0018 31.3519 83.9589 30.9655 83.9589 30.5761C83.9589 30.1853 84.0018 29.8034 84.0875 29.4291C84.1732 29.0547 84.2987 28.6984 84.4656 28.3609C84.8158 27.6506 85.3315 27.0801 86.014 26.6494C86.6965 26.2186 87.5166 26.0029 88.475 26.0029C89.9303 26.0029 91.0698 26.4765 91.8929 27.4236C92.6288 28.2775 92.9964 29.3329 92.9964 30.5904C92.9964 31.848 92.625 32.9033 91.8809 33.7572C91.372 34.339 90.7015 34.7427 89.8709 34.9674C89.4492 35.084 88.9869 35.1418 88.484 35.1418C87.9804 35.1418 87.5204 35.0847 87.1032 34.9712C86.6853 34.8562 86.3094 34.6961 85.9749 34.4901C85.6412 34.2849 85.348 34.0391 85.0955 33.7542C84.8429 33.4686 84.6332 33.1567 84.4656 32.8169Z" fill="black"/>
  <path d="M95.2271 34.6653H96.5034L96.5094 26.469H95.5067V26.0089H99.1764V26.469H98.1143V34.6653H99.9393C100.986 34.6653 101.62 34.1827 101.843 33.2183C101.906 32.9394 101.948 32.6545 101.968 32.3606H102.433V35.1366H95.2271V34.6653Z" fill="black"/>
  <path d="M109.806 32.1236L108.111 28.0022L106.555 32.1236H109.806ZM104.305 34.6711H104.95L108.314 26.0087H109.006L112.603 34.6711H113.212V35.1363H109.961V34.6711H110.856L109.996 32.5889H106.375L105.588 34.6711H106.661V35.1363H104.305V34.6711Z" fill="black"/>
  <path d="M115.12 34.6713H116.295L116.3 26.475H115.12V26.0089H119.081V26.475H117.905V34.6713H119.081V35.1366H115.12V34.6713Z" fill="black"/>
  <path d="M125.265 30.5014C125.989 30.5014 126.498 30.2707 126.793 29.8084C127.007 29.4731 127.115 28.9973 127.115 28.3817C127.115 27.7653 126.99 27.3113 126.739 27.0189C126.429 26.6558 125.902 26.4747 125.159 26.4747H123.893V30.5014H125.265ZM125.373 26.0094C127.659 26.0094 128.803 26.7716 128.803 28.2975C128.803 28.989 128.592 29.5408 128.171 29.9512C127.749 30.3624 127.184 30.6255 126.476 30.7397C127.411 30.8953 128.051 31.3764 128.398 32.1837C128.537 32.5092 128.659 32.8685 128.764 33.2601C128.87 33.6517 128.964 33.9298 129.048 34.0922C129.23 34.4583 129.501 34.6409 129.859 34.6409H130.098L130.109 35.1363H129.268C128.489 35.1363 127.964 35.0311 127.693 34.8206C127.419 34.6056 127.211 34.1824 127.067 33.5495C126.936 32.973 126.829 32.5791 126.745 32.3686C126.569 31.9153 126.343 31.559 126.064 31.3005C125.786 31.0419 125.426 30.9126 124.985 30.9126H123.893V34.6409H125.289V35.1363H121.322V34.6409H122.283L122.289 26.4747H121.322V26.0094H125.373Z" fill="black"/>
  <path d="M131.665 34.6653H132.858L132.864 26.475H131.665V26.0089H139.378V28.6721H138.913C138.894 28.3233 138.866 28.0137 138.83 27.743C138.794 27.4724 138.718 27.2424 138.603 27.0538C138.373 26.6674 137.903 26.475 137.195 26.475H134.469V30.2206H135.96C136.299 30.2206 136.558 30.1695 136.739 30.0665C136.92 29.9628 137.03 29.7824 137.07 29.523C137.077 29.4479 137.085 29.3689 137.091 29.284C137.097 29.1998 137.104 29.1164 137.111 29.0337H137.613V32.1118H137.111C137.104 32.0156 137.097 31.9051 137.091 31.7796C137.085 31.6533 137.077 31.5488 137.07 31.4654C137.03 31.176 136.918 30.9738 136.733 30.8588C136.548 30.7438 136.29 30.6859 135.96 30.6859H134.469V34.6653H137.023C138.068 34.6653 138.703 34.1962 138.925 33.2589C138.989 32.9883 139.03 32.7109 139.051 32.4253H139.516V35.1366H131.665V34.6653Z" fill="black"/>
  <path d="M142.172 38.8881H142.512V36.352H142.168V36.2076H143.132L143.964 38.2627L144.791 36.2076H145.724V36.352H145.364V38.8881H145.757V39.0317H144.529V38.8881H144.865L144.867 36.4317L143.841 38.9934H143.767L142.68 36.4181V38.8881H143.014V39.0317H142.172V38.8881Z" fill="black"/>
  <path d="M147.378 38.8881C147.766 38.8881 148.043 38.7919 148.21 38.598C148.377 38.4048 148.462 38.0756 148.462 37.611V37.5539C148.454 37.1082 148.365 36.797 148.193 36.6188C148.021 36.4407 147.757 36.352 147.402 36.352H147.049V38.8881H147.378ZM146.182 38.8881H146.551L146.552 36.352H146.182V36.2076H147.426C148.168 36.2076 148.647 36.4422 148.865 36.9112C148.956 37.1044 149 37.3329 149 37.5975C149 37.95 148.898 38.2567 148.694 38.5191C148.529 38.7295 148.29 38.8776 147.976 38.9641C147.811 39.0092 147.619 39.0317 147.4 39.0317H146.182V38.8881Z" fill="black"/>
</svg>`;
