import Minze, { MinzeElement, Reactive, EventListeners } from "minze";
import { confirmPasswordReset } from "firebase/auth";
import { PASSWORD_REGEX, escapeHtml, unescapeHtml } from "@/modules/utils";
import { MainModule } from "@/modules/main";
import { Routing } from "@/modules/routing";
import { Locale } from "@/modules/locale";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";

Locale.getInstance().registerKeySet("fr", "resetPassword", {
  title: "Réinitialiser le mot de passe",
  password: "Mot de passe",
  passwordPlaceholder: "Saisir le mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  confirmPasswordPlaceholder: "Confirmer le mot de passe",
  description:
    "Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre",
  errors: {
    passwordCriterias:
      "Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial",
    passwordMatch: "Les mots de passe ne correspondent pas",
    passwordLength: "Le mot de passe doit contenir au moins 8 caractères",
    passwordRequired: "Veuillez saisir votre mot de passe",
    misc: "Quelque chose s'est mal passé",
  },
});

Locale.getInstance().registerKeySet("en", "resetPassword", {
  title: "Reset password",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  confirmPassword: "Confirm password",
  confirmPasswordPlaceholder: "Confirm your password",
  description:
    "Your password must contain at least 8 characters, one uppercase, one lowercase and one digit",
  errors: {
    passwordCriterias:
      "Your password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character",
    passwordMatch: "Passwords do not match",
    passwordLength: "Password must contain at least 8 characters",
    passwordRequired: "Please enter your password",
    misc: "Something went wrong",
  },
});

export interface BResetPassword {
  password?: string;
  confirmPassword?: string;
}

export class BResetPassword extends MinzeElement {
  reactive: Reactive = [
    ["password", ""],
    ["confirmPassword", ""],
  ];

  errors: any = {
    password: false,
    confirmPassword: false,
  };

  onReady(): void | Promise<void> {
    const mainModule = MainModule.getInstance();
    const userData = mainModule.getAuth().getUserData();

    if (userData && userData.profile) {
    }
  }

  goBack = () => {
    const mainModule = MainModule.getInstance();
    const routing = mainModule.getRouting();

    routing.goToLastPage();
  };

  save = async () => {
    const passwordElement = this.select(
      '[name="password"]',
    ) as HTMLInputElement;
    const confirmPasswordElement = this.select(
      '[name="confirmPassword"]',
    ) as HTMLInputElement;
    const password = unescapeHtml(passwordElement.value);
    const confirmPassword = unescapeHtml(confirmPasswordElement.value);
    this.password = password;
    this.confirmPassword = confirmPassword;

    for (const key in this.errors) {
      this.errors[key] = false;
    }
    if (!this.password) {
      this.errors.password = Locale.getKey(
        "resetPassword.errors.passwordRequired",
      );
    }

    if (this.password && (this.password as string).length < 8) {
      this.errors.password = Locale.getKey(
        "resetPassword.errors.passwordLength",
      );
    }
    if (this.password && !PASSWORD_REGEX.test(this.password as string)) {
      this.errors.password = Locale.getKey(
        "resetPassword.errors.passwordCriterias",
      );
    }
    if (this.password !== this.confirmPassword) {
      this.errors.confirmPassword = Locale.getKey(
        "resetPassword.errors.passwordMatch",
      );
    }

    let hasError = false;
    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      this.rerender();
      return;
    }

    const mainModule = MainModule.getInstance();
    const auth = mainModule.getAuth().getFirebaseAuth();
    try {
      let oobCode = new URLSearchParams(window.location.search).get("oobCode");
      await confirmPasswordReset(
        auth,
        oobCode as string,
        this.password as string,
      );
      const language = Locale.language;

      mainModule
        .getRouting()
        .goTo(
          `${Routing.ROUTES[language].login}?recover-password-success=true`,
        );
    } catch (error) {
      console.log(error);
      this.errors.password = Locale.getKey("resetPassword.errors.misc");
    }

    this.rerender();
  };

  html = () => `
   <b-modal narrow="true" hide-when-logged="true" modal-title="${Locale.getKey("resetPassword.title")}" titleSize="36px">
      <div slot="b-modal-top">
        <b-button class="link-back">
          ${ChevronLeftIcon}
          ${Locale.getKey("general.previous")}
        </b-button>
      </div>
      <div slot="b-modal-content" class="form">
        <b-text font-preset="small">
          ${Locale.getKey("resetPassword.description")}
        </b-text>
         <b-input id="password" name="password" type="password" error="${this.errors.password}" placeholder="${Locale.getKey("resetPassword.passwordPlaceholder")}" default-value="${escapeHtml(this.password)}" label="${Locale.getKey("resetPassword.password")}"></b-input>
         <b-input id="confirmPassword" name="confirmPassword" type="password" error="${this.errors.confirmPassword}" placeholder="${Locale.getKey("resetPassword.confirmPasswordPlaceholder")}" default-value="${escapeHtml(this.confirmPassword)}" label="${Locale.getKey("resetPassword.confirmPassword")}"></b-input>
        <b-button
          name="save"
          width="${window.matchMedia("(max-width: 730px)").matches ? "100%" : "fit-content"}"
        >
          ${Locale.getKey("general.modify")}
        </b-button>
    </div>
    </b-modal>
  `;

  css = () => `
    :host {

    }

    .link-back {
      display: flex;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="save"]', "click", this.save],
    [".link-back", "click", this.goBack.bind(this)],
  ];
}
