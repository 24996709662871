import { generateId } from "@/modules/utils";

export class Global {
  private _injectedElements: any = {};

  public registerElement(element: any): void {
    let id = generateId();
    this._injectedElements[id] = element;
    element.setAttribute("data-registered-gid", id);
  }

  public getElement(gid: string): any {
    return this._injectedElements[gid];
  }

  public removeElement(gid: string): void {
    let element = this._injectedElements[gid];
    if (element) {
      element.remove();
      delete this._injectedElements[gid];
    }
  }

  public injectElementIntoBody(element: any): void {
    document.body.appendChild(element);
    this.registerElement(element);
  }

  public injectElementIntoHead(element: any): void {
    document.head.appendChild(element);
    this.registerElement(element);
  }

  public injectStyles(styles: string): void {
    const style = document.createElement("style");
    style.innerHTML = styles;
    style.setAttribute("data-generated-id", generateId());
    this.injectElementIntoHead(style);
  }
}
