import axios from "redaxios";
import { Emitter } from "@/modules/emitter";
import { Routing } from "@/modules/routing";
import { TabAccountant } from "@/modules/tabAccountant";
import { Locale } from "@/modules/locale";
import { Attribute, canShowOnThisTab } from "@/modules/utils";
import { AuthModule } from "@/modules/auth";
import { Global } from "@/modules/global";
import { config } from "@/constants/config";

const projectName = config.projectName;

export interface MainModuleConfig {
  onAuthStateChanged?: { (instance: MainModule, data: any): Promise<void> };
  onAuthInitialized?: { (instance: MainModule): Promise<void> };
  onUserSignedIn?: { (instance: MainModule, data: any): Promise<void> };
  onUserSignedOut?: { (instance: MainModule): Promise<void> };
  onFinishInitialization?: { (instance: MainModule): Promise<void> };
  onInitialize?: { (instance: MainModule): void };
}

export class MainModule {
  private static instance: MainModule;
  public static getInstance(): MainModule {
    if (!MainModule.instance) {
      MainModule.instance = new MainModule();
    }
    return MainModule.instance;
  }
  public static setInstance(instance: MainModule): void {
    MainModule.instance = instance;
  }
  private _tabAccountant = TabAccountant.getInstance();
  private _isLoggedIn = false;
  private _ee: Emitter = new Emitter();
  private _auth: AuthModule | null = null;
  private _user: any = null;
  private _lang = "en";
  private _global: Global = new Global();
  private _routing: Routing = new Routing();
  private _locale = Locale.getInstance();
  private _mainModuleConfig: MainModuleConfig | null = null;
  private _extraModules: any = {};

  constructor(config?: MainModuleConfig) {
    if (config) {
      this._mainModuleConfig = config;
    }
    this._lang = this.getLocale().getLanguage();
    this._routing.setLanguage(this._lang);
    this._auth = new AuthModule(this.getEE());

    if (config?.onInitialize) {
      config.onInitialize(this);
    }

    this.initialize();
    MainModule.setInstance(this);
  }

  public async initialize(): Promise<void> {
    this._ee.on(
      `${projectName}:auth_initialized`,
      this.onAuthInitialized.bind(this),
    );
    this._ee.on(
      `${projectName}:user_state_changed`,
      this.onAuthStateChanged.bind(this),
    );
    this._ee.on(
      `${projectName}:finished_signing_in:success`,
      this.onSignIn.bind(this),
    );
    this._ee.on(
      `${projectName}:user_logged_out`,
      this.onUserLoggedOut.bind(this),
    );
    if (!this._auth) this._auth = new AuthModule(this.getEE());

    this._auth.initialize();

    let cfg = this.getCfg();
    if (cfg?.onFinishInitialization) {
      await cfg.onFinishInitialization(this);
    }
  }

  private async onAuthInitialized(): Promise<void> {
    let cfg = this.getCfg();
    if (cfg?.onAuthInitialized) {
      await cfg.onAuthInitialized(this);
    }
  }

  private async onAuthStateChanged(data: any): Promise<void> {
    let cfg = this.getCfg();
    if (cfg?.onAuthStateChanged) {
      await cfg.onAuthStateChanged(this, data);
    }
  }

  private async onUserLoggedOut(): Promise<void> {
    let cfg = this.getCfg();
    if (cfg?.onUserSignedOut) {
      await cfg.onUserSignedOut(this);
    }
  }

  private async onSignIn(data: any): Promise<void> {
    let cfg = this.getCfg();
    if (cfg?.onUserSignedIn) {
      await cfg.onUserSignedIn(this, data);
    }
  }

  private getCfg(): MainModuleConfig {
    return this._mainModuleConfig || {};
  }

  public getAuth(): AuthModule {
    if (!this._auth) {
      this._auth = new AuthModule(this._ee);
    }
    return this._auth;
  }

  public getTabAccountant(): TabAccountant {
    if (!this._tabAccountant) {
      this._tabAccountant = new TabAccountant();
    }
    return this._tabAccountant;
  }

  /*
   * This method is used to register modules from other derivate projects
   * Think of this like custom modules that we don't want to put in the codebase of the boilerplate
   */
  public registerModule(key: string, instance: any): void {
    this._extraModules[key] = instance;
  }

  /*
   * This method is used to get the registered modules from other derivate projects
   */
  public getModule<Type>(key: string): Type {
    return this._extraModules[key];
  }

  public getRouting(): Routing {
    return this._routing;
  }

  public getLang(): any {
    return this._lang;
  }

  public getLocale(): Locale {
    return this._locale;
  }

  public getGlobal(): Global {
    return this._global;
  }

  public getEE(): Emitter {
    if (!this._ee) {
      this._ee = new Emitter();
    }
    return this._ee;
  }

  public injectIntoDOM(tag: string, attributes?: Attribute[]) {
    const el = document.createElement(tag);

    if (attributes) {
      for (let k = 0; k < attributes.length; k++) {
        el.setAttribute(attributes[k].name, attributes[k].value);
      }
    }

    document.body.appendChild(el);
  }

  public showToastAlert(message: string, timeout: number = 3000) {
    const toast = document.createElement("b-toast-container");
    toast.setAttribute("data-message", message);
    toast.setAttribute("data-timeout", timeout.toString());
    document.body.appendChild(toast);
  }

  public analyticsTrack(eventName: string, data: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  }

  public analyticsSetCustomData(data: any = {}) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...data,
    });
  }
}
