import Minze, { Attrs, EventListeners, MinzeElement, Reactive } from "minze";
import { Font } from "@/constants";
import { generateId } from "@/modules/utils";

interface InputFieldChangeEvent {
  fieldId: string;
  value: string;
  defaultValue: boolean;
  mobileLabelCentered: boolean;
}

export interface BCheckbox {
  defaultValue?: boolean;
  checked?: boolean;
}

export class BCheckbox extends MinzeElement {
  reactive: Reactive = [["checked", false, true]];

  attrs: Attrs = [["default-value", false]];
  id: string = generateId();

  static observedAttributes = ["default-value"];

  afterAttributeChange(attr: string, value: string, oldValue: string) {
    if (attr === "default-value") {
      this.checked = value === "true";
    }
  }

  async onReady(): Promise<void> {
    const defVal = this.defaultValue as boolean;
    this.checked = defVal ?? false;
  }

  html = () => {
    let checked = this.checked ? "true" : "false";
    return `
      <div class="checkbox-container">
        <input
          type="checkbox"
          id="${this.id}"
          name="${this.name}"
          ${this.checked ? `checked` : ""}
          class="checkbox"
        />
        <label for="${this.id}">
          <slot></slot>
        </label>
      </div>
    `;
  };

  css = () => `
    :host {
      width: 100%;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 12px;
    }

    .checkbox {
      border-radius: 2px;
      text-align: center;
      appearance: none;
      border: 1px solid #000;
      height: 16px;
      min-height: 16px;
      max-height: 16px;
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      display: block;
      position: relative;
      font-size: 16px;
      line-height: 1px;
      background: transparent;
      color: transparent;
      cursor: pointer;
    }

    .checkbox:checked::after {
      content: '✓';
      color: #d11d1d;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 1;
    }

    .checkbox:checked {
      border-color: #d11d1d;
    }

    label {
      cursor: pointer;
      font-family: ${Font.family};
      font-size: 14px;
      color: #000;
      margin-left: 12px;
    }

    @media screen and (max-width: 730px) {
    }
  `;

  handleInput(event: Event) {
    this.checked = (event as any)?.target?.checked;
    this.rerender();
    this.dispatch("toggle", {
      checked: this.checked,
      target: this,
    });
  }

  eventListeners: EventListeners = [
    ["input", "change", this.handleInput.bind(this)],
  ];
}
