import Minze, { MinzeElement, Reactive, EventListeners } from "minze";
import {
  EMAIL_REGEX,
  escapeHtml,
  unescapeHtml,
  waitUntil,
  writeCss,
} from "@/modules/utils";
import { MainModule } from "@/modules/main";
import { Locale } from "@/modules/locale";
import { Font } from "@/constants";
import ChevronLeftIcon from "iconoir/icons/regular/nav-arrow-left.svg?raw";
import GoogleIcon from "iconoir/icons/regular/google.svg?raw";
import FacebookIcon from "iconoir/icons/regular/facebook-tag.svg?raw";
import { config, Style } from "@/constants";

const projectName = config.projectName;

Locale.getInstance().registerKeySet("fr", "ssclogin", {
  welcome: "Bienvenue chez",
  company: "Succès Scolaire",
  title: "Connexion",
  login: "Me connecter",
  email: "Courriel",
  emailPlaceholder: "Entrez votre adresse courriel",
  password: "Mot de passe",
  passwordPlaceholder: "Entrez votre mot de passe",
  forgotPassword: "<u>Mot de passe oublié?</u>",
  noAccountYet: "Pas encore de compte? <u>M'inscrire</u>",
  errors: {
    invalidEmail: "Adresse courriel invalide",
    invalidPassword: "Mot de passe invalide",
    general: "Erreur de connexion",
    emailRequired: "Ce champ est requis",
    passwordRequired: "Ce champ est requis",
    noAccount: "Aucun compte n'est associé à cette adresse courriel",
    userTypeNotAllowed: "Vous n'êtes pas autorisé à vous connecter ici",
    apiError: "Erreur de connexion",
  },
  providers: {
    google: "Se connecter avec Google",
    facebook: "Se connecter avec Facebook",
  },
});

Locale.getInstance().registerKeySet("en", "ssclogin", {
  welcome: "Welcome to",
  company: "Succès Scolaire",
  title: "Login",
  login: "Sign in",
  email: "E-mail",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  forgotPassword: "<u>Forgot your password?</u>",
  noAccountYet: "Still don't have an account? <u>Sign up</u>",
  errors: {
    invalidEmail: "Invalid email",
    invalidPassword: "Invalid password",
    general: "Login error",
    emailRequired: "This field is required",
    passwordRequired: "This field is required",
    noAccount: "No account is associated with this email address",
    userTypeNotAllowed: "You are not allowed to login here",
    apiError: "Login error",
  },
  providers: {
    google: "Login with Google",
    facebook: "Login with Facebook",
  },
});

export interface SscLogin {
  email?: string;
  password?: string;
  bgImageUrl?: string;
  loading?: boolean;
}

export class SscLogin extends MinzeElement {
  reactive: Reactive = [
    ["loading", false],
    ["email", ""],
    ["password", ""],
    [
      "bgImageUrl",
      "https://storage.googleapis.com/successcolaire.appspot.com/public/account_screen_background.png",
    ],
  ];

  private errors: any = {
    email: false,
    password: false,
    general: false,
  };

  async onReady(): Promise<void> {
    this.email = localStorage.getItem("rememberedEmail") || "";
    this.loading = false;
    const mainModule = MainModule.getInstance();
    const ee = mainModule.getEE();
    ee.on(`${projectName}:user_not_authorized_here_because_of_user_type`, () => {
      this.errors.general = Locale.getKey("ssclogin.errors.userTypeNotAllowed");
      this.rerender();
    });

    ee.on(`${projectName}:user_not_authorized_here_because_of_api`, () => {
      this.errors.general = Locale.getKey("ssclogin.errors.apiError");
      this.rerender();
    });
  }

  onDestroy() {}
  
  onLogin = async () => {
    for (const key in this.errors) {
      this.errors[key] = false;
    }
    let isUsername = false;
    let error: any = null;
    const emailElement: any = this.select('[name="email"]');
    const email = unescapeHtml(emailElement?.value);
    this.email = email;

    const passwordElement: any = this.select('[name="password"]');
    const password = unescapeHtml(passwordElement?.value);
    this.password = password;

    const mainModule = MainModule.getInstance();
    if (!email) {
      this.errors.email = Locale.getKey("ssclogin.errors.emailRequired");
    } else if (!EMAIL_REGEX.test(email)) {
      isUsername = true;
    }

    if (!password) {
      this.errors.password = Locale.getKey("ssclogin.errors.passwordRequired");
    }

    let hasError = false;

    for (const key in this.errors) {
      if (this.errors[key]) {
        hasError = true;
      }
    }

    if (hasError) {
      this.rerender();
      return;
    }

    if (email && password) {
      this.email = email;
      this.password = password;
      localStorage.setItem("rememberedEmail", email);

      mainModule.analyticsTrack("login", { method: "Email" });
      this.loading = true;
      if (isUsername) {
        error = (await mainModule
          .getAuth()
          .loginWithUsernameAndPassword(email, password)) as any;
      } else {
        error = (await mainModule.getAuth().login(email, password)) as any;
      }
    }

    if (error) {
      this.loading = false;
      if (error.code === "auth/user-not-found") {
        this.errors.email = Locale.getKey("ssclogin.errors.noAccount");
      } else if (error.code === "auth/wrong-password") {
        this.errors.password = Locale.getKey("ssclogin.errors.invalidPassword");
      } else {
        this.errors.password = true;
        this.errors.email = true;
        this.errors.general = Locale.getKey("ssclogin.errors.general");
      }
    }

    this.rerender();
  };

  onSignupWithGoogle() {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("login", { method: "Google" });
    mainModule.getAuth().callGoogleSignIn();
  }

  onSignupWithFacebook() {
    const mainModule = MainModule.getInstance();
    mainModule.analyticsTrack("login", { method: "Facebook" });
    mainModule.getAuth().callFacebookSignIn();
  }

  html = () => {
    return `
      <ssc-modal
        background-image-url="${this.bgImageUrl}"
        narrow="true"
        modal-title="">
        <div slot="b-modal-overlay" stlye="z-index: 1">
          <ssc-logo style="z-index:1"></ssc-logo>
        </div>
        <div slot="b-modal-content" class="form">
          <div class="left">
          </div>
          <div class="right">
            <div class="title">
              <h3>${Locale.getKey("ssclogin.welcome")}</h3>
              <h1>${Locale.getKey("ssclogin.company")}</h1>
            </div>
            <div class="title-spacer"></div>
            <ssc-input error="${this.errors.email}" name="email" placeholder="${Locale.getKey("ssclogin.emailPlaceholder")}" default-value="${escapeHtml(this.email)}" label="${Locale.getKey("ssclogin.email")}"></ssc-input>
            <ssc-input error="${this.errors.password}" name="password" type="password" placeholder="${Locale.getKey("ssclogin.passwordPlaceholder")}" default-value="${escapeHtml(this.password)}" label="${Locale.getKey("ssclogin.password")}"></ssc-input>
            <a href="/ecole/motdepasse_oublie" name="forgot-password" class="forgot-password">${Locale.getKey("ssclogin.forgotPassword")}</a>
            ${this.errors.general ? `<b-error error="${this.errors.general}"></b-error>` : ``}
            <b-button name="login" class="${this.loading ? "loading" : ""}" preset="sscPrimary">
              ${Locale.getKey("ssclogin.login")}
            </b-button>
          </div>
        </div>
        <ssc-footer slot="b-modal-footer">
        </ssc-footer>
      </ssc-modal>
    `;
  };

  css = () => `
    :host {
    }

    .switch-to-signup {
      cursor: pointer;
    }

    .forgot-password-link {
      display: flex;
      justify-content: flex-end;
    }

    .or {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 20px 0;
      color: #dd1d1d;
    }

    .or b-text {
      color: #dd1d1d;
    }

    .or-line {
      width: 100%;
      height: 1px;
      background-color: #dd1d1d;
    }


    .form {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    a, a:visited {
      font-style: normal;
      font-weight: ${Font.weight.medium};
      font-size: 14px;
      text-decoration: none;
      color: #000;
    }

    a>u:hover {
      color: #dd1d1d;
    }

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
      padding: 37px 94px 46px 68px;
      gap: 20px;
    }


    .left {
      background: #FECA36;
      background-image: url('https://storage.googleapis.com/successcolaire.appspot.com/public/repetitive_dot.png');
      background-size: 22px;
      flex: 1;
      box-sizing: border-box;
      border: 30px solid #FECA36;
      max-width: 200px;
    }

    h3 {
      color: #0D465B;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 200% */
    }

    h1 {
      color: #0D465B;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }

    .title-spacer {
      height: 40px;
    }
    ssc-logo {
      margin: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    ${writeCss(Style.link.template, "a[href], a[href]:visited, a[href]:link")}

    a.forgot-password {
      cursor: pointer;
    }

    .loading {
      pointer-events: none;
      opacity: 0.5;
    }
  `;

  eventListeners: EventListeners = [
    ['[name="login"]', "click", this.onLogin.bind(this)],
    [
      ".google-sign-provider-button",
      "click",
      this.onSignupWithGoogle.bind(this),
    ],
    [
      ".facebook-sign-provider-button",
      "click",
      this.onSignupWithFacebook.bind(this),
    ],
  ];
}
