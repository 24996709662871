const SHOULD_LOG = true;
const SHOULD_LOG_TRACE = true;
export function ConsoleLog(should_log: boolean, ...args: any[]) {
  if (should_log && SHOULD_LOG) {
    if (SHOULD_LOG_TRACE) {
      console.trace(...args);
    } else {
      console.log(...args);
    }
  }
}

export function ConsoleError(should_log: boolean, ...args: any[]) {
  if (should_log && SHOULD_LOG) {
    if (SHOULD_LOG_TRACE) {
      console.trace(...args);
    }
    console.error(...args);
  }
}

export function ConsoleWarn(should_log: boolean, ...args: any[]) {
  if (should_log && SHOULD_LOG) {
    if (SHOULD_LOG_TRACE) {
      console.trace(...args);
    }
    console.warn(...args);
  }
}
