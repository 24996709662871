import { EventListeners, MinzeElement, Reactive, type Attrs } from "minze";
import { Font, config } from "@/constants";
import { MainModule } from "@/modules/main";
import CloseIcon from "iconoir/icons/regular/x-square.svg?raw";
const projectName = config.projectName;

export interface SscModal {
  modalStatus?: string;
  modalTitle?: string | boolean;
  leftTitle?: boolean;
  largeTitle?: boolean;
  isFixed?: boolean;
  mobileTitleCentered?: boolean;
  fullHeight?: boolean;
  isCustomProfileModal?: boolean;
  hideWhenLogged?: boolean;
  showWhenLogged?: boolean;
  narrow?: boolean;
  backgroundImageUrl?: string;
}

export class SscModal extends MinzeElement {
  attrs: Attrs = [
    "modal-title",
    "max-width",
    "hide-when-logged",
    "show-when-logged",
    "narrow",
    "left-title",
    "large-title",
    "is-fixed",
    "mobile-title-centered",
    "full-height",
    "is-custom-profile-modal",
    "background-image-url",
  ];
  reactive: Reactive = [["modalStatus", "hide"]];
  static observedAttributes = ["modal-title", "background-image-url"];

  isLoggedIn: boolean = false;

  afterAttributeChange(name: string, oldValue: string, newValue: string) {
    if (name === "modal-title" && oldValue !== newValue) {
      this.rerender();
    }
  }
  _authHandler: any = null;
  onStart(): void {
    let mainModule = MainModule.getInstance();
    this._authHandler = this.handleAuthChanged.bind(this);
    mainModule
      .getEE()
      .on(`${projectName}:user_state_changed`, this._authHandler);
  }

  onReady(): void {
    let mainModule = MainModule.getInstance();
    this._authHandler = this.handleAuthChanged.bind(this);
    this.updateModalStatus(mainModule.getAuth().isLoggedIn());
    if (this.isFixed) document.body.style.overflowY = "hidden";
  }

  onDestroy(): void {
    let mainModule = MainModule.getInstance();
    mainModule
      .getEE()
      .off(`${projectName}:user_state_changed`, this._authHandler);
    if (this.isFixed) document.body.style.overflowY = "auto";
  }

  async handleAuthChanged(data?: any): Promise<void> {
    this.isLoggedIn = data?.isLoggedIn;
    this.updateModalStatus(data?.isLoggedIn);
  }

  updateModalStatus(isLoggedIn?: boolean): void {
    if (
      (this.hideWhenLogged === true && isLoggedIn) ||
      (this.showWhenLogged === true && !isLoggedIn)
    ) {
      this.modalStatus = "hide";
    } else if (
      (this.hideWhenLogged === true && !isLoggedIn) ||
      (this.showWhenLogged === true && isLoggedIn)
    ) {
      this.modalStatus = "show";
    } else {
      this.modalStatus = "show";
    }

    this.rerender();
  }

  html = () => {
    let titleSize = this.largeTitle ? "titleDesktop" : "titleDesktopSmall";

    let mobile = window.innerWidth < 960;

    if (mobile) {
      titleSize = this.largeTitle ? "titleMobile" : "titleMobileSmall";
    }

    return `
    <div id="${projectName}-modal-overlay" class="b-modal-overlay ${this.modalStatus}">
      <slot name="b-modal-overlay"></slot>
    </div>
    <div class="b-modal ${this.modalStatus} ${this.isFixed ? "fixed" : ""}">
      <div class="b-modal-content ${this.narrow ? "narrow" : ""}">
        <slot name="b-modal-top"></slot>
        <div class="title-section">
          <span class="b-modal-close">${CloseIcon}</span>
          <b-text font-preset=${titleSize} class="b-modal-title">${
            this.modalTitle && this.modalTitle !== true ? this.modalTitle : ""
          }</b-text>
        </div>
        <slot name="b-modal-content"></slot>
      </div>
    </div>
    <div class="b-modal-footer">
      <slot name="b-modal-footer"></slot>
    </div>
  `;
  };

  css = () => {
    let overlayBackground: string =
      this.backgroundImageUrl && this.backgroundImageUrl !== true
        ? `
      background-size: cover;
      background-position: center;
      opacity: 1;
      background-color: #fff;
      background-image: url(${this.backgroundImageUrl});
    `
        : `
      opacity: 0.4;
    `;
    return `
    :host {
      ${this.isCustomProfileModal ? "" : `position: fixed;`}
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 1400;
      font-family: ${Font.family};
    }
    .title-section {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      ${this.modalTitle && !this.modalTitle === true ? "" : "margin: 0;"}
    }
    .b-modal-overlay {
      position: absolute; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      ${overlayBackground}
    }

    slot[name="b-modal-overlay"] {
    }

    slot[name="b-modal-footer"] {
    }

    .b-modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 1399; /* 1402; */
    }

    .b-modal-overlay.show *{
      display: block
    }


    .b-modal-overlay.hide *{
      display: none
    }

    .b-modal {
      display: flex;
      ${this.isCustomProfileModal ? "position: relative;" : "position: absolute;"}
      ${this.isCustomProfileModal ? "" : "padding-top: 145px;"}
      ${this.isCustomProfileModal ? "min-height: 100%;" : ""}
      left: 0px;
      top: 0px;
      z-index: 1401;
      bottom: 0px;
      ${this.isCustomProfileModal ? "" : "overflow: auto;"}
      ${this.isCustomProfileModal ? "padding-top: 110px;" : ""}
      ${this.isCustomProfileModal ? "padding-bottom: max(5%, 65px);" : ""}
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .b-modal.fixed {
      position: fixed;
    }

    .b-modal.show {
      display: block
    }

    .b-modal.hide {
      display: none
    }

    .b-modal-content {
      overflow: hidden;
      background-color: #fff;
      margin: auto;
      width: 100%;
      border-radius: 8px;
      padding: 0px;
      position: relative;
      border: 1.4px solid #ECF2F8;
      ${
        this.fullHeight
          ? `
        height: min-content;
        min-height: -webkit-fill-available;
      `
          : ""
      }
    }

    .b-modal-content::before {

    }

    .b-modal-close {
      display: none;
      color: #fff;
      font-size: 28px;
      font-weight: ${Font.weight.bold};
      position: absolute;
      top: 45px;
      right: 16px;
    }

    .b-modal-close:hover,
    .b-modal-close:focus {
      color: #aaaaaa;
      text-decoration: none;
      cursor: pointer;
    }

    .b-modal-title {
      text-transform: uppercase;
      text-align: ${this.leftTitle ? "left" : "center"};
      line-height: 1.2;
    }

    @media screen and (max-width: 991px) {
      .b-modal-title {
        text-transform: uppercase;
        text-align: ${this.mobileTitleCentered ? "center" : "left"};
      }
    }

    @media only screen and (min-width: 300px) and (max-width: 620px){
      .b-modal {
        width: 100%;
        ${this.isCustomProfileModal ? "" : "height: 100%;"}
        ${this.isCustomProfileModal ? "min-height: 100%;" : ""}
        ${this.isCustomProfileModal ? "padding-bottom: 90px;" : ""}
      }
    }

    @media only screen and (min-width: 621px) and (max-width: 960px){
      .b-modal {
        width: 100%;
        ${this.isCustomProfileModal ? "" : "height: 100%;"}
        ${this.isCustomProfileModal ? "min-height: 100%;" : ""}
        ${this.isCustomProfileModal ? "padding-bottom: 90px;" : ""}
      }

      .b-modal-content {
        max-width: 540px;
      }

      .narrow {
        max-width:640px;
      }

    }

    @media only screen and (min-width: 960px) and (max-width: 1366px){
      .b-modal {
        width: 100%;
      }

      .b-modal-content {
        max-width: 940px;
      }

      .narrow {
        max-width:640px;
      }

    }

    @media only screen and (min-width: 1366px) {
      .b-modal {
        width: 100%;
      }

      .b-modal-content {
        max-width: 1366px;
      }

      .narrow {
        max-width:640px;
      }
    }

    @media only screen and (max-width: 991px) {
      :host {
        height: calc(100% - 73px); /* Mobile Navbar adjustment */
      }
      .b-modal {
        padding-top: 9rem;
      }
    }
  `;
  };

  eventListeners: EventListeners = [];
}
