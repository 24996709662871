import Minze, {
  EventListeners,
  MinzeAttrs,
  MinzeElement,
  Reactive,
} from "minze";
import { parseInlineStyle } from "@/modules/utils";

export interface BBox {
  hide?: boolean;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  flexWrap?: string;
  flexGrow?: string;
  flexDirection?: string;
  width?: string;
  minWidth?: string;
  height?: string;
  textAlign?: string;
  columnGap?: string;
  rowGap?: string;
  color?: string;
  position?: string;
  left?: string;
  bottom?: string;
  right?: string;
  top?: string;
  padding?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderRadius?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  cursor?: string;
  background?: string;
  inlineStyle?: string;
  flex?: string;
}

export class BBox extends MinzeElement {
  attrs: MinzeAttrs = [
    "hide",
    ["mt"],
    ["mb"],
    ["ml"],
    ["mr"],
    "display",
    ["alignItems"],
    ["justifyContent"],
    "flexWrap",
    "flexGrow",
    "flexDirection",
    ["width"],
    ["minWidth"],
    ["height", "100%"],
    "textAlign",
    "columnGap",
    "rowGap",
    "color",
    "position",
    "left",
    "bottom",
    "right",
    "top",
    "padding",
    "border",
    "borderTop",
    "borderBottom",
    "borderRadius",
    "fontSize",
    "fontWeight",
    "lineHeight",
    "cursor",
    "background",
    "inline-style",
  ];

  static observedAttributes = [
    "hide",
    "mt",
    "mb",
    "ml",
    "mr",
    "display",
    "alignItems",
    "justifyContent",
    "flexWrap",
    "flexGrow",
    "flexDirection",
    "width",
    "minWidth",
    "height",
    "textAlign",
    "columnGap",
    "rowGap",
    "color",
    "position",
    "left",
    "bottom",
    "right",
    "top",
    "padding",
    "border",
    "borderTop",
    "borderBottom",
    "borderRadius",
    "fontSize",
    "fontWeight",
    "lineHeight",
    "cursor",
    "inline-style",
  ];

  html = () => {
    if (this.hide) return "";

    return `
      <slot></slot>
    `;
  };

  css = () => `
    :host {
      ${this.height ? `height: ${this.height};` : ""}
      ${this.width ? `width: ${this.width};` : ""}
      ${this.minWidth ? `min-width: ${this.minWidth};` : ""}
      ${this.flex ? `flex: ${this.flex};` : ""}
      ${this.mt ? `margin-top: ${this.mt};` : ""}
      ${this.mb ? `margin-bottom: ${this.mb};` : ""}
      ${this.ml ? `margin-left: ${this.ml};` : ""}
      ${this.mr ? `margin-right: ${this.mr};` : ""}
      ${this.display ? `display: ${this.display};` : ""}
      ${this.flexGrow ? `flex-grow: ${this.flexGrow};` : ""}
      ${this.alignItems ? `align-items: ${this.alignItems};` : ""}
      ${this.justifyContent ? `justify-content: ${this.justifyContent};` : ""}
      ${this.flexWrap ? `flex-wrap: ${this.flexWrap};` : ""}
      ${this.flexDirection ? `flex-direction: ${this.flexDirection};` : ""}
      ${this.textAlign ? `text-align: ${this.textAlign};` : ""}
      ${this.columnGap ? `column-gap: ${this.columnGap};` : ""}
      ${this.rowGap ? `row-gap: ${this.rowGap};` : ""}
      ${this.color ? `color: ${this.color};` : ""}
      ${this.position ? `position: ${this.position};` : ""}
      ${this.left ? `left: ${this.left};` : ""}
      ${this.right ? `right: ${this.right};` : ""}
      ${this.top ? `top: ${this.top};` : ""}
      ${this.bottom ? `bottom: ${this.bottom};` : ""}
      ${this.padding ? `padding: ${this.padding};` : ""}
      ${this.border ? `border: ${this.border};` : ""}
      ${this.borderTop ? `border-top: ${this.borderTop};` : ""}
      ${this.borderBottom ? `border-bottom: ${this.borderBottom};` : ""}
      ${this.borderRadius ? `border-radius: ${this.borderRadius};` : ""}
      ${this.fontSize ? `font-size: ${this.fontSize};` : ""}
      ${this.fontWeight ? `font-weight: ${this.fontWeight};` : ""}
      ${this.lineHeight ? `line-height: ${this.lineHeight};` : ""}
      ${this.cursor ? `cursor: ${this.cursor}` : ""}
      ${this.background ? `background: ${this.background}` : ""}
    }

    ::slotted(*) {
    }

    ${parseInlineStyle(this.inlineStyle as string)}
  `;
}
