import { FontLato } from "./font";

export const Style = {
  link: {
    template: `
      {{{selector}}}: {
        color: #005B88;
        font-family: ${FontLato.family};
        font-size: 12px;
        font-style: normal;
        font-weight: ${FontLato.weight.medium};
        line-height: 20px; /* 166.667% */
        text-decoration-line: underline;
      }
    `,
  },
  button: {
    template: `
      {{{selector}}} {
        color: #FFF;
        text-align: center;
        font-family: ${FontLato.family};
        font-size: 16px;
        font-style: normal;
        font-weight: ${FontLato.weight.bold};
        line-height: 24px; /* 150% */
        border-radius: 8px;
        background: #005B88;
        box-shadow: 0px 3px 4px -2px rgba(28, 36, 51, 0.10);
        display: flex;
        padding: 12px 20px;
        align-items: flex-start;
        gap: 10px;
      }
    `,
  },
};
