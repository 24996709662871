declare global {
  interface Window {
    BMM: MainModule;
    dataLayer: any[];
  }
}

import { Minze } from "minze";
const modules = import.meta.glob(
  "./lib/**/!(*.spec|*.test|*.stories).@(ts|js)",
);
const defineAll = Minze.defineAll;
import "@/locale/index";
import { MainModule } from "@/modules/main";
import mainConfig from "@/mainConfig";
import { config } from "@/constants/config";
MainModule.setInstance(new MainModule(mainConfig));
MainModule.getInstance().getGlobal().injectStyles(`
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
`);

if (config?.debugInstance) {
  window.BMM = MainModule.getInstance();
}

export { modules as default, modules, defineAll };
