import { Locale } from "../modules/locale";

Locale.getInstance().registerKeySet("en", "weekdays", {
  long: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  },
  short: {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  },
});

Locale.getInstance().registerKeySet("fr", "weekdays", {
  long: {
    1: "Lundi",
    2: "Mardi",
    3: "Mercredi",
    4: "Jeudi",
    5: "Vendredi",
    6: "Samedi",
    7: "Dimanche",
  },
  short: {
    1: "Lun",
    2: "Mars",
    3: "Mer",
    4: "Jeu",
    5: "Ven",
    6: "Sam",
    7: "Dim",
  },
});
