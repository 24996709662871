import { MainModuleConfig } from "@/modules/main";
import axios from "redaxios";

export default {
  onAuthStateChanged: async (instance, data) => {
    console.log("on auth state changed");
  },
  onAuthInitialized: async (instance) => {
    console.log("on auth init");
  },
  onFinishInitialization: async (instance) => {
    console.log("on finish init");
  },
  onUserSignedIn: async (instance, data) => {
    console.log("on sign in", data);
  },
  onUserSignedOut: async (instance) => {
    console.log("on sign out");
  },
  onInitialize: (instance) => {
    console.log("constructor init");
  },
} as MainModuleConfig;
